/* RESPONSIBLE TEAM: team-phone */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import { type Router } from '@ember/routing';
import { taskFor } from 'ember-concurrency-ts';
import type CustomReportsService from 'embercom/services/custom-reports-service';

export default class CallsRoute extends IntercomRoute {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare router: Router;
  @service declare intercomEventService: any;
  @service declare customReportsService: CustomReportsService;

  reportName = 'calls_dashboard';
  reportContext = 'calls';
  analytics = {
    place: 'calls',
  };

  activate() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: this.reportName,
      context: this.reportContext,
    });
  }

  get titleToken() {
    return this.intl.t('reporting.calls.title');
  }

  beforeModel() {
    if (this.appService.app.cannotSeeDeprecatedReports) {
      this.customReportsService.routeToOverviewPage();
    }
  }

  async model() {
    let settings = this._fetchReportingSetting();
    let conversationAttributeDescriptors = ConversationAttributeDescriptor.peekAllAndMaybeLoad();
    let metricsSetup = taskFor(this.reportingMetrics.setup).perform();

    return hash({
      settings,
      conversationAttributeDescriptors,
      metricsSetup, // not used in the template directly, just needs to be awaited
    });
  }

  _fetchReportingSetting() {
    return (
      this.store.peekAll('reporting/settings')?.firstObject ||
      this.store.queryRecord('reporting/settings', {})
    );
  }
}

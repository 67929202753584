/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { type Router } from '@ember/routing';

export default class AppsAppBillingCancelConfirmRoute extends Route {
  @service declare router: Router;
  @service declare appService: any;

  beforeModel() {
    if (this.appService.app.canSeeChurnSaveInterventionChanges) {
      this.router.transitionTo('apps.app.settings.subscription.billing.settings', {
        queryParams: {
          redirect_source: 'exit_survey',
        },
      });
    }
  }
}

/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { hideIntercomWidgetWhenLoaded } from 'embercom/lib/intercom-widget-helper';
import type Nexus from 'embercom/services/nexus';

export default class AppsAppCopilotRoute extends Route {
  @service declare appService: any;
  @service declare session: Session;
  @service declare nexus: Nexus;

  async beforeModel() {
    hideIntercomWidgetWhenLoaded();

    this.session.switchWorkspace(this.appService.app.id);
    await this.session.getTeammate(this.appService.app.id);

    this.nexus.start();
  }
}

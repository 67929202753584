/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type ArrayProxy from '@ember/array/proxy';
import type SdkApp from 'embercom/models/sdk-app';
import type SdkAppService from 'embercom/services/sdk-app-service';
import { hash } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class FinStandaloneDeployIntercomMessengerRoute extends Route {
  @service declare router: RouterService;
  @service declare appService: { app: { id: string } };
  @service declare finStandaloneService: FinStandaloneService;
  @service declare sdkAppService: SdkAppService;
  @service declare store: Store;

  get app() {
    return this.appService.app;
  }

  async model() {
    let sdkApps = this.store.peekAll('sdk-app') as ArrayProxy<SdkApp>;
    let iosSdkApp = sdkApps.find((sdkApp) => sdkApp.platform === 'iOS');
    let androidSdkApp = sdkApps.find((sdkApp) => sdkApp.platform === 'Android');

    if (iosSdkApp && androidSdkApp) {
      this.sdkAppService.initWithSdkApps(iosSdkApp, androidSdkApp);
    }

    return hash({
      sdkApps:
        iosSdkApp && androidSdkApp
          ? [iosSdkApp, androidSdkApp]
          : taskFor(this.sdkAppService.loadSdkApps).perform(),
    });
  }
}

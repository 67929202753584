/* RESPONSIBLE TEAM: team-messenger */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default class MessengerIndexRoute extends IntercomRoute {
  @service router;

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-messenger',
    };
  }

  async model(transition, params) {
    let { tab, section } = transition;
    this.router.transitionTo('apps.app.messenger.web', {
      queryParams: {
        tab: tab || 'content',
        section: section || '',
      },
    });
  }
}

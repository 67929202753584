/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class DataRoute extends Route {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare appService: any;

  async beforeModel() {
    let promises = [];

    if (this.appService.app.hasStandalonePlatform('zendesk')) {
      if (taskFor(this.finStandaloneService.zendeskDataLoader.loadZendeskDataFields).isRunning) {
        promises.push(
          taskFor(this.finStandaloneService.zendeskDataLoader.loadZendeskDataFields).lastRunning!,
        );
      } else {
        promises.push(
          taskFor(this.finStandaloneService.zendeskDataLoader.loadZendeskDataFields).perform(),
        );
      }
    }

    if (this.appService.app.hasStandalonePlatform('salesforce')) {
      if (taskFor(this.finStandaloneService.loadSalesforceDataFields).isRunning) {
        promises.push(taskFor(this.finStandaloneService.loadSalesforceDataFields).lastRunning!);
      } else {
        promises.push(taskFor(this.finStandaloneService.loadSalesforceDataFields).perform());
      }
    }

    // we call load data fields in the finStandaloneService.initialize method
    // but we are not returning them in the returned promise list
    // the intention here is to wait for these promises to resolve before
    // we navigate to this route
    await Promise.all(promises);
  }
}

/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router';

export default class IntercomMessengerWorkflowRoute extends Route {
  @service declare router: Router;

  beforeModel(transition: Transition) {
    this.router.transitionTo(
      'apps.app.standalone.deploy.intercom-messenger.workflow.show',
      transition.to.params.id,
    );
  }
}

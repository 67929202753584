/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  store: service(),
  analytics: {
    place: 'article_list',
  },
  titleToken: 'Articles',

  beforeModel() {
    // Get all the folders in the base articles route
    // (not ideal, but we need them for the data models to resolve correctly -
    // if an article in the article list is in a section for example).
    // An alternative would be to sideload the folder into the article payload,
    // but I don't think we need that now.
    return this.store.query('articles/article-group', { include_article_stats: false });
  },
});

/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ReportBase from 'embercom/routes/apps/app/reports/base/report';
import { inject as service } from '@ember/service';

export default class CustomerSupportOverviewRoute extends ReportBase {
  @service store;
  @service customReportsService;
  reportName = 'summary_conversations_report';
  reportContext = 'summary';
  titleToken = 'Summary';
  analytics = {
    place: 'conversations',
  };
  instrumentationDelay = 3000;
  dashboardIdentifier = 'inbox-summary-conversations';
  chartIdentifiers = [
    'first-response-time',
    'first-response-time-counter',
    'time-to-close',
    'time-to-close-counter',
  ];
  shouldRedirectFromParent = true;

  get currentTeammate() {
    return this.modelFor('apps');
  }

  findDataConfig(dataConfigs, chartId) {
    let result = dataConfigs.findBy('chart_identifier', chartId);

    if (!result) {
      result = this.store.createRecord('reporting/chart', {
        dashboard_identifier: this.dashboardIdentifier,
        chart_identifier: chartId,
        aggregation_method: 'median',
        app_id: this.app.id,
      });
    }

    return result;
  }

  beforeModel() {
    super.beforeModel(...arguments);

    let app = this.modelFor('apps.app');
    if (app.get('inboxIsNotActive')) {
      return this.replaceWith('apps.app.inbox');
    }
    if (
      this.appService.app.cannotSeeCSOverviewReport ||
      this.appService.app.cannotSeeDeprecatedReports
    ) {
      this.customReportsService.routeToOverviewPage();
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('teammate', this.currentTeammate);

    let {
      interval,
      comparisonStartMoment,
      comparisonEndMoment,
      startMoment,
      endMoment,
      inDays,
      timezone,
    } = this.range;

    controller.set('rangeParams', {
      interval,
      comparisonStartMoment,
      comparisonEndMoment,
      startMoment,
      endMoment,
      inDays,
      timezone,
    });
  }

  fetchSignals() {
    return {};
  }
}

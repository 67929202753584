/* RESPONSIBLE TEAM: team-product-exploration */

import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { ReactRoute } from 'embercom/lib/react-route';
import type IntlService from 'embercom/services/intl';

class NotificationsRoute extends IntercomRoute {
  @service declare store: $TSFixMe;
  @service declare intl: IntlService;

  analytics = {
    section: 'settings',
    place: 'personal.notifications',
  };

  reactEnabled() {
    return true;
  }

  activate() {
    this.store
      .createRecord('admin-pageview', {
        pageKey: 'notifications_settings',
      })
      .save();
  }

  get titleToken() {
    return this.intl.t('new-settings.submenu.personal.notifications');
  }
}

export type NotificationsRouteType = NotificationsRoute;

export default ReactRoute(NotificationsRoute);

/* RESPONSIBLE TEAM: team-frontend-tech  */

import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import {
  routeRedirector,
  SETTINGS_REDIRECT_MAPPING,
  isOnMigratedRoute,
} from 'embercom/lib/settings/settings-redirect-map';
import { hash } from 'rsvp';
import { isPresent } from '@ember/utils';
import { standaloneSettingRedirectRoute } from 'embercom/helpers/standalone/standalone-routes';

export default class SettingsRoute extends Route {
  @service declare router: RouterService;
  @service declare store: any;
  @service declare customerService: $TSFixMe;

  analytics = {
    section: 'settings',
  };

  async beforeModel(transition: Transition) {
    if (this.customerService.fetchCustomerTask.isRunning) {
      // make sure the customer data is loaded before we navigate to the settings page
      await this.customerService.fetchCustomerTask.lastRunning!;
    }

    let app: any = this.modelFor('apps.app');

    if (app.canUseStandalone && !app.allowAccessOutsideStandaloneSection) {
      let customer: any =
        this.customerService.customer || this.customerService.fetchCustomerTask.last?.value;

      if (isPresent(standaloneSettingRedirectRoute(app, customer, transition.to.name))) {
        this.router.transitionTo(
          standaloneSettingRedirectRoute(app, customer, transition.to.name)!,
        );
      }
    }

    if (!isOnMigratedRoute(transition.to.name)) {
      routeRedirector(
        transition,
        this.router,
        SETTINGS_REDIRECT_MAPPING,
        'apps.app.settings.index',
      );
    }
  }

  async model() {
    return hash({
      helpCenters: this.store.findAll('help-center-site').catch(() => {}),
      stripeMigration: this.customerService.getStripeMigration(),
    });
  }

  @action
  willTransition(transition: Transition) {
    if (transition.to.name.includes('settings')) {
      window.document
        .querySelector('.main__content-outlet')
        ?.scrollTo({ top: 0, behavior: 'instant' });
    }
  }
}

/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';

export default class StandaloneChannelsOverviewRoute extends Route {
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);
    this.router.transitionTo('apps.app.standalone.deploy.overview', this.appService.app.id);
  }
}

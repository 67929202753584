/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';

export default Route.extend(EventTracking, {
  analytics: {
    place: 'articles',
  },
  titleToken() {
    let app = this.modelFor('apps.app');
    return app.get('articlesProduct.name');
  },
});

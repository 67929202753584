/* RESPONSIBLE TEAM: team-data-foundations */
import { inject as service } from '@ember/service';
import ProductSettingsRoute from 'embercom/routes/apps/app/settings/base/product-settings-route';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';

export default class PeopleRoute extends ProductSettingsRoute {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get titleToken() {
    return this.intl.t('new-settings.submenu.data.people');
  }

  get app() {
    return this.appService.app;
  }

  beforeModel(transition: Transition): void | Promise<unknown> {
    super.beforeModel(transition);

    if (this.app.canUseStandalone && !this.app.allowAccessOutsideStandaloneSection) {
      this.router.transitionTo('apps.app.settings.standalone.data.people');
    }
  }

  async model(): Promise<any> {
    let eventSettings = await this.store.findRecord('event-settings', this.app.id);
    let eventTrackers = await this.store.findAll('event-tracker');
    let attributeSettings = await this.store.findRecord('data-attribute-settings', this.app.id);
    let qualificationAttributes = await this.store.findAll('people/qualification-attribute');
    return {
      eventSettings,
      eventTrackers,
      attributeSettings,
      qualificationAttributes,
    };
  }
}

/* RESPONSIBLE TEAM: team-knowledge-foundations */
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import ajax from 'embercom/lib/ajax';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type Transition from '@ember/routing/transition';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';

// start of Q2C1FY23
const TLS_CUTOFF_DATE = Date.parse('2022-05-01');

export type SettingsModel = {
  customDomain: any;
  isManagedCustomDomainEnabled: boolean;
  site: HelpCenterSite;
};

export default class HelpcenterMain extends BaseSettingsRoute {
  @service declare intl: IntlService;
  @service declare helpCenterService: $TSFixMe;
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  async beforeModel(transition: Transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      CAN_MANAGE_ARTICLES_PERMISSION,
      transition,
    );
    if (transition.to.name === 'apps.app.settings.helpcenter.workspace-helpcenter.index') {
      this.replaceWith('apps.app.settings.helpcenter.workspace-helpcenter.collections');
    }
  }

  get titleToken() {
    return this.intl.t('new-settings.helpcenter.header.title');
  }

  async model(params: $TSFixMe): Promise<SettingsModel> {
    await this.helpCenterService.maybeFetchSite(params.id);
    let site: HelpCenterSite = this.helpCenterService.site;

    if (isPresent(params.selectedLocaleId) && !site.supportsLocale(params.selectedLocaleId)) {
      this.transitionTo({ queryParams: { selectedLocaleId: undefined } });
    }

    if (site.meetsQuickTlsCriteria === null) {
      this.helpCenterService.forceFetchSite(params.id);
    }

    let id = params.id;
    let isManagedCustomDomainEnabled =
      this.appService.app.shouldBypassTlsCutoffDate ||
      Date.parse(this.appService.app.created_at) > TLS_CUTOFF_DATE;
    let customDomain = null;
    if (isManagedCustomDomainEnabled) {
      let customDomains = await ajax({
        type: 'GET',
        url: `/ember/custom_domains?app_id=${this.appService.app.id}&help_center_id=${id}`,
      });
      customDomain = customDomains.length > 0 ? customDomains[0] : null;
    }
    return { customDomain, isManagedCustomDomainEnabled, site };
  }
}

/* RESPONSIBLE TEAM: team-purchase */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';

export default class IntershopRoute extends Route {
  @service declare appService: any;
  @service declare router: RouterService;

  beforeModel() {
    this.router.transitionTo('apps.app.settings.channels.messenger.install', {
      queryParams: { tab: 'web', section: 'web-messenger-setup' },
    });
  }
}

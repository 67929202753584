/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { alias, readOnly } from '@ember/object/computed';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';

export default Route.extend({
  intercomEventService: service(),
  appService: service(),
  permissionsService: service(),
  intercomConfirmService: service(),
  app: readOnly('appService.app'),
  analytics: {
    section: 'articles',
    place: 'article_edit',
  },

  showRoute: 'apps.app.articles.articles.article.show',

  beforeModel(transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      CAN_MANAGE_ARTICLES_PERMISSION,
      transition,
      this.showRoute,
    );
  },

  activate() {
    window.onbeforeunload = () => {
      if (this.shouldSave()) {
        return 'Warning: You have unsaved changes. Do you want to leave the page?';
      }
    };
  },
  deactivate() {
    window.onbeforeunload = undefined;
  },
  titleToken(model) {
    return `${model.get('titleForDisplay')} | Articles`;
  },
  invalidTitle(changed) {
    if ('title' in changed) {
      return !changed.title[0] && !changed.title[1];
    }
  },
  invalidState(changed) {
    if ('state' in changed) {
      return !changed.state[0] && changed.state[1] === 'published';
    }
  },
  invalidBlocks(changed) {
    if ('jsonBlocks' in changed) {
      let blocks = changed.jsonBlocks;
      let newAndUnchanged = (!blocks[0] || blocks[0].length === 0) && !blocks[1][0].text;
      return newAndUnchanged || JSON.stringify(blocks[1][0]) === JSON.stringify(blocks[0][0]);
    }
  },
  shouldSave() {
    let changed = this.currentModel.changedAttributes();

    if (this.invalidTitle(changed)) {
      delete changed.title;
    }
    if (this.invalidState(changed)) {
      delete changed.state;
    }
    if (this.invalidBlocks(changed)) {
      delete changed.jsonBlocks;
    }

    let countChanges = Object.keys(changed).length;
    if (this.currentModel.ruleset) {
      let changedRuleset = this.currentModel.ruleset.changedAttributes();
      countChanges += Object.keys(changedRuleset).length;
    }

    this.currentModel.articleContents.forEach((a) => {
      countChanges += Object.keys(a.changedAttributes()).length;
    });

    return countChanges > 0;
  },
  afterModel(model) {
    this.set('originalFolder', model.get('folder'));
    if (model.ruleset) {
      model.ruleset.get('fetchAudiencePreviewTask').perform();
    }
  },

  selectedLocale: alias('controller.helpCenterService.currentlySelectedLocale.localeId'),

  actions: {
    cancelChangesAndCloseArticle() {
      this.transitionTo('apps.app.articles.articles.article.show', this.currentModel);
    },
    didTransition() {
      this.controller.set('keepAsDraft', this.currentModel.get('isDraft'));

      this.currentModel.createContentIfNone(this.selectedLocale);

      this.intercomEventService.trackEvent('educate-article-edit', {
        action: 'change_to_edit',
        object: 'article',
        place: 'article',
        owner: 'educate',
        article_id: this.get('controller.model.id'),
      });
    },
    async willTransition(transition) {
      if (this.shouldSave()) {
        let model = this.get('controller.model');
        if (!this.isCancelling) {
          this.set('isCancelling', true);
          transition.abort();
          let confirmOptions = {
            title: 'Close without saving?',
            primaryButtonType: 'primary-destructive',
            confirmButtonText: 'Close without saving',
            cancelButtonText: 'Keep editing',
            body: "You'll lose your changes if you don't save first.",
          };
          this.set('isCancelling', false);
          let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
          if (!isConfirmed) {
            return;
          }

          model.rollbackAttributes();
          // Cancelling here needs to reset the folder if its been changed.
          // reloading a model doesn't reload its relationships meaning the relationship changes
          // have to be tracked manually
          // https://github.com/emberjs/data/issues/1913
          if (model.get('folder.name') !== this.get('originalFolder.name')) {
            model.set('folder', this.originalFolder);
          }
          model.articleContents.forEach((a) => {
            a.rollbackAttributes();
          });

          transition.retry();
        }
      }
    },
  },
});

/* RESPONSIBLE TEAM: team-purchase */
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { INCLUDED_TIERS_FROM_PRICING_ENDPOINT } from 'embercom/lib/billing';
import {
  SMS_ID,
  PROACTIVE_SUPPORT_ID,
  PEOPLE_REACHED_TIER_1_ID,
  PEOPLE_REACHED_TIER_2_ID,
  PEOPLE_REACHED_TIER_3_ID,
} from 'embercom/lib/billing';
import type Product from 'embercom/models/product';
import { hash } from 'rsvp';
import type Plan from 'embercom/models/plan';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';

const PEOPLE_REACHED_TIERS = [
  Number(PEOPLE_REACHED_TIER_1_ID),
  Number(PEOPLE_REACHED_TIER_2_ID),
  Number(PEOPLE_REACHED_TIER_3_ID),
];

export default class IntershopAddOnsAddOnPricingRoute extends Route {
  @service declare permissionsService: any;
  @service declare store: Store;
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare customerService: any;
  @service declare intercomEventService: any;
  @service declare intershopService: any;

  beforeModel(): void {
    if (!this.permissionsService.currentAdminCan('can_access_billing_settings')) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'blocked',
        object: 'intershop_addons_pricing',
        place: 'billing_permissions',
      });
      this.router.transitionTo('apps.app.intershop.add-ons.add-on', {
        queryParams: {
          showPermissionsModal: true,
        },
      });
      return;
    }

    if (!this.intershopService.canAccessPricing) {
      this.router.transitionTo('apps.app.intershop.add-ons.add-on');
      return;
    }

    if (this.product.id === SMS_ID) {
      this.router.transitionTo('apps.app.intershop.add-ons.add-on');
      return;
    }
  }

  model(): any {
    return hash({
      product: this.product,
      plan: this.plan,
      contract: this.contract,
    });
  }

  get product(): Product {
    // @ts-ignore
    return this.modelFor('apps.app.intershop.add-ons.add-on').product as Product;
  }

  get plan(): Plan {
    // @ts-ignore
    return this.modelFor('apps.app.intershop.add-ons.add-on').plan as Plan;
  }

  get contract(): any {
    return this.store.findRecord('billing/contract', this.appService.app.id, {
      reload: false,
      backgroundReload: false,
    });
  }

  get peopleReachedTierId() {
    let tierId = this.customerService.billableCustomerPlanIds.find((id: any) =>
      PEOPLE_REACHED_TIERS.includes(id),
    );
    return tierId ?? Number(PEOPLE_REACHED_TIER_1_ID);
  }

  async afterModel(model: any) {
    if (model.contract.isSecondarySubscription) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'blocked',
        object: 'intershop_plans_pricing',
        place: 'secondary_workspace',
      });
      this.router.transitionTo('apps.app.intershop.add-ons.add-on', {
        queryParams: {
          showSecondaryWorkspaceModal: true,
        },
      });
      return;
    }

    let isValidPlanCombination = await taskFor(this.getPrices).perform();

    if (!isValidPlanCombination) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'blocked',
        object: 'intershop_addons_pricing',
        place: 'product_incompatible',
      });
      this.router.transitionTo('apps.app.intershop.add-ons.add-on', {
        queryParams: {
          showInvalidPlanCombinationModal: true,
        },
      });
      return;
    }
  }

  @task *getPrices() {
    let planId = [this.plan.idAsNumber];
    let checkValidPlanIds = [...this.customerService.billableCustomerPlanIds, ...planId].uniq();
    let priceSets = [checkValidPlanIds, planId];
    if (this.product.id === PROACTIVE_SUPPORT_ID) {
      checkValidPlanIds.push(this.peopleReachedTierId);
      priceSets.push([this.peopleReachedTierId]);
    }
    let pricesNotLoaded = priceSets.filter(
      (ids) =>
        !this.customerService.isPriceLoaded(
          ids,
          this.customerService?.currentPricingModelIdentifier,
        ),
    );

    if (!pricesNotLoaded.length) {
      return this.customerService.getPriceFromPlanIds(checkValidPlanIds).plan_combination_valid;
    } else {
      yield this.customerService.bulkLoadPrices(
        pricesNotLoaded.map((ids) => ({
          planIds: ids,
          source: 'intershop-pricing-route',
          includePlanCombinationValidation: true,
          includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
        })),
      );
      return this.customerService.getPriceFromPlanIds(checkValidPlanIds).plan_combination_valid;
    }
  }
}

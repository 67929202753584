/* RESPONSIBLE TEAM: team-data-foundations */
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import AttributeSettingsBase from 'embercom/routes/apps/app/settings/base/attribute-settings-base';
import type Transition from '@ember/routing/transition';

export default class CompaniesRoute extends AttributeSettingsBase {
  @service declare intl: IntlService;
  @service declare router: RouterService;

  // For triggering the focus of corresponding attribute description table cell
  queryParams: any = ['attributeForDescriptionEditing'];
  attributeForDescriptionEditing = null;

  get titleToken() {
    return this.intl.t('new-settings.submenu.data.companies');
  }

  beforeModel(transition: Transition): void | Promise<unknown> {
    super.beforeModel(transition);

    let app: any = this.modelFor('apps/app');

    if (app.canUseStandalone && !app.allowAccessOutsideStandaloneSection) {
      this.router.transitionTo('apps.app.settings.standalone.data.companies');
    }
  }
}

/* RESPONSIBLE TEAM: team-standalone */
import ShowReportRoute from 'embercom/routes/apps/app/reports/custom-reports/report/show';

import { inject as service } from '@ember/service';
import type ReportingViews from 'embercom/services/reporting-views';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import { taskFor } from 'ember-concurrency-ts';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import { tracked } from '@glimmer/tracking';
import ENV from 'embercom/config/environment';
type ModelParams = {
  report_id: string;
};

export default class FinStandaloneReportsShowRoute extends ShowReportRoute {
  @service declare reportingViews: ReportingViews;
  @service declare store: Store;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare reportingTemplates: ReportingTemplates;
  @service declare guideLibraryService: GuideLibraryService;

  @tracked timeout: ReturnType<typeof setTimeout> | null = null;

  async model(params: ModelParams) {
    let promises = [];
    promises.push(taskFor(this.reportingMetrics.setup).perform());
    promises.push(taskFor(this.reportingViews.setUp).perform());
    promises.push(this.reportingTemplates.loadTemplates());
    promises.push(ConversationAttributeDescriptor.peekAllAndMaybeLoad());
    await Promise.all(promises);

    let report = await this.getReport(params.report_id);
    return await super.model({ report_id: report.id });
  }

  async getReport(reportId: string) {
    return (
      (await this.store.peekRecord('reporting/custom/report', reportId)) ||
      this.store.findRecord('reporting/custom/report', reportId)
    );
  }

  afterModel() {
    let guideLibraryService = this.guideLibraryService;
    this.timeout = setTimeout(() => {
      guideLibraryService.markStepCompleted('fin_sa_onboarding_analyze_fin_performance');
    }, ENV.APP._5000MS);
  }

  deactivate() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}

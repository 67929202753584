/* RESPONSIBLE TEAM: team-reporting */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Transition from '@ember/routing/-private/transition';

export default class FinAIAgentAnalyzePerformanceRoute extends Route {
  @service declare intl: IntlService;
  @service declare permissionsService: $TSFixMe;

  get titleToken() {
    return this.intl.t('ai-agent.analyze.performance');
  }

  async beforeModel(transition: Transition) {
    this.permissionsService.ensurePermissionWhenTransitioning('can_access_reporting', transition);
  }
}

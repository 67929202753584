/* RESPONSIBLE TEAM: team-ai-agent */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class AutomationFin extends IntercomRoute {
  @service declare router: RouterService;
  @service declare appService: any;

  redirect(): any {
    if (this.appService.app.canUseStandalone) {
      return this.router.transitionTo('apps.app.standalone.guidance');
    } else {
      return this.router.transitionTo('apps.app.automation.fin-ai-agent.setup');
    }
  }
}

/* RESPONSIBLE TEAM: team-purchase */

import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';

export default class Checkout extends Route {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  private static TRANSITIONS = ['apps.app.checkout.success', 'apps.app.checkout.invite-teammates'];

  beforeModel(transition: Transition) {
    if (!this.isValidTransition(transition)) {
      this.router.transitionTo('apps.app.index');
    }
  }

  private isValidTransition(transition: Transition): boolean {
    return Checkout.TRANSITIONS.includes(transition?.to?.name);
  }
}

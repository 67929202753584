/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { inject as service } from '@ember/service';
import type Controller from '@ember/controller';
import type Transition from '@ember/routing/transition';
import type ZendeskMessagingTestController from 'embercom/controllers/apps/app/standalone/deploy/zendesk/messaging/test';

export default class Test extends Route {
  @service declare finStandaloneService: FinStandaloneService;

  queryParams = {
    section: {
      refreshModel: false,
    },
  };

  setupController(
    controller: ZendeskMessagingTestController,
    model: unknown,
    transition: Transition,
  ) {
    super.setupController(controller, model, transition);

    let params = this.paramsFor(this.routeName) as { section?: string };
    let section = params.section;

    if (section) {
      controller.set('section', section);
    } else {
      controller.set('section', this.openSectionId);
    }
  }

  resetController(controller: Controller, isExiting: boolean, _: Transition) {
    if (isExiting) {
      (controller as ZendeskMessagingTestController).set('section', null);
    }
  }

  get openSectionId() {
    if (!this.finStandaloneService.hasContentForFin) {
      return 'fin-ready';
    }

    if (this.zendeskConfig.sunshineIntegration.isSunshineConnected) {
      return 'zendesk-setup-test-fin';
    }

    return null;
  }

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }
}

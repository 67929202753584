/* RESPONSIBLE TEAM: team-proactive-support */
import PerformanceReportBase from 'embercom/routes/apps/app/reports/base/performance-report';

import { hash } from 'rsvp';
import {
  parsedESFiltersForSignal,
  setControllerFilterProperties,
  filterQueryParams,
  setRouteFilterProperties,
} from 'embercom/lib/reporting/flexible/filter-helpers';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { inject as service } from '@ember/service';

const QUERY_PARAMS_ES_MAPPINGS = {
  teammateId: 'teammate_id',
  teamsParticipated: 'teammate_id', //a list of teammate ids is passed to ES
  scopingTagIds: 'conversation_tag_ids',
  teammatesAssigned: 'admin_assignee_id',
  channels: 'channel_type',
  continents: 'user_location.continent_code',
  countries: 'user_location.country_name',
  startedByUser: 'conversation_started_by_user',
  teamsAssigned: 'team_assignee_id',
  customAttributes: 'custom_attributes',
  topics: 'topic',
};
const FILTER_TYPES = Object.keys(QUERY_PARAMS_ES_MAPPINGS);

export default PerformanceReportBase.extend({
  intl: service(),
  customReportsService: service(),
  appService: service(),
  reportName: 'performance_happiness_report',
  analytics: {
    place: 'happiness',
  },
  queryParams: filterQueryParams(FILTER_TYPES),
  shouldRedirectFromParent: true,
  mergedProperties: ['queryParams'],

  titleToken() {
    return this.intl.t('reporting.customer-satisfaction.title-token');
  },

  filters() {
    let queryParamMappings = Object.assign({}, QUERY_PARAMS_ES_MAPPINGS);
    if (!this.appService.app.canUseTopicFilter) {
      delete queryParamMappings.topics;
    }

    return parsedESFiltersForSignal(this, queryParamMappings, { supportSameFieldFilters: false });
  },

  beforeModel(transition) {
    this._super(...arguments);

    if (this.appService.app.cannotSeeDeprecatedReports) {
      this.customReportsService.routeToOverviewPage();
    }

    //setup filters
    let filters = FILTER_TYPES.filter((filter) => {
      if (filter === 'topics') {
        return this.appService.app.canUseTopicFilter;
      }

      return true;
    });
    setRouteFilterProperties(this, transition.to.queryParams, filters);
  },

  async model() {
    let conversationAttributeDescriptors = [];

    let settings = {
      canUseTopicFilter: this.appService.app.canUseTopicFilter,
    };

    if (this.app.canUseFeature('conversation_attributes')) {
      conversationAttributeDescriptors =
        await ConversationAttributeDescriptor.peekAllAndMaybeLoad();
    }

    return hash({
      settings,
      conversationAttributeDescriptors,
    });
  },

  setupController(controller, model) {
    this._super(controller, model);
    setControllerFilterProperties(this, controller, FILTER_TYPES);
  },

  fetchSignals() {
    return {};
  },
});

/* RESPONSIBLE TEAM: team-ai-agent */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/-private/transition';
import { hash } from 'rsvp';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';

const OPERATOR_SETTINGS_PERMISSION = 'can_create_and_edit_bots';

export default class AutomationFinAIAgentProfilesRoute extends IntercomRoute {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare permissionsService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.profiles',
  };

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.profiles.title');
  }

  beforeModel(transition: Transition) {
    this.permissionsService.ensurePermissionWhenTransitioning(
      OPERATOR_SETTINGS_PERMISSION,
      transition,
    );
  }

  async model() {
    let appId = this.appService.app.id;
    let messengerSettings = await this.store.findRecord('messenger-settings/all', appId);
    let multilingualSettings = await this.getMultilingualSettings();

    return hash({
      previewConfiguration: messengerSettings.previewConfiguration,
      aiAgentSettings: this.store.findRecord('ai-agent/settings', appId),
      aiAgentToneOfVoiceSettings: this.store.findRecord('ai-agent/tone-of-voice-settings', appId),
      aiAgentQuickReplySettings: this.store.findRecord(
        'operator-settings/ai-agent-quick-replies',
        appId,
      ),
      aiAgentMultilingualSettings: multilingualSettings,
      languages: messengerSettings.languages,
      conversationImports: this.store.findAll('conversation-import'),
    });
  }

  setupController(
    controller: any,
    model: {
      previewConfiguration: any;
      aiAgentSettings: any;
      aiAgentToneOfVoiceSettings: any;
      aiAgentQuickReplySettings: any;
      aiAgentMultilingualSettings: any;
      languages: any;
    },
  ) {
    controller.setProperties({
      previewConfiguration: model.previewConfiguration,
      aiAgentSettings: model.aiAgentSettings,
      aiAgentToneOfVoiceSettings: model.aiAgentToneOfVoiceSettings,
      aiAgentQuickReplySettings: model.aiAgentQuickReplySettings,
      aiAgentMultilingualSettings: model.aiAgentMultilingualSettings,
      languages: model.languages,
    });
  }

  async getMultilingualSettings(): Promise<$TSFixMe> {
    return this.store.findRecord('ai-agent/multilingual-settings', this.appService.app.id);
  }

  @action refreshRoute() {
    this.refresh();
  }
}

/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default class FinAIAgentDeployPhoneRoute extends Route {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  queryParams = {
    tab: {
      refreshModel: true,
    },
  };

  get titleToken() {
    return this.intl.t('ai-agent.deploy.phone');
  }

  model() {
    return {
      hasSomePhoneFeaturesEnabled:
        this.appService.app.canUseFinVoicePlayground || this.appService.app.canUseFinVoiceEasySetup,
    };
  }
}

/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-private-routing-service */
import ContentEditorRoute from 'embercom/routes/apps/app/content/edit';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import {
  OPERATOR_OUTBOUND_CUSTOM_BOT_EDITOR_ROUTE as OPERATOR_EDIT_ROUTE,
  OPERATOR_CUSTOM_BOT_OVERVIEW_ROUTE,
} from 'embercom/lib/operator/custom-bots/constants';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';

export default class OutboundCustomBotEditor extends ContentEditorRoute {
  @service appService;
  @service router;
  @service store;
  @service appService;

  get app() {
    return this.appService.app;
  }

  beforeModel(transition) {
    super.beforeModel(...arguments);
    let descriptors = this.store.findAll('conversation-attributes/descriptor');

    this.outboundHomeService.setSearchParams({
      selectedMatchBehaviorValue: null,
    });

    let queryParams = {};
    let { to } = transition;
    let rulesetId = to?.params?.id || to?.queryParams?.id;

    if (this.shouldTransitionToEdit(rulesetId)) {
      queryParams.mode = 'edit';
    }

    // Since https://github.com/emberjs/ember.js/pull/19450 the router service's
    // currentRoute object is clobbered with an unhelpful "intermediate" loading
    // state which in this route's case has the name `apps.app.content.loading`
    // so here we instead go reaching into internal router API to check the
    // route before the loading route transition
    let currentRouterState = getOwner(this).lookup('router:main').currentState;
    let previousRouteWasOperatorIndex = Object.keys(
      currentRouterState.routerJsState.params,
    ).includes(OPERATOR_CUSTOM_BOT_OVERVIEW_ROUTE);

    if (
      this.router.currentRoute?.name === OPERATOR_CUSTOM_BOT_OVERVIEW_ROUTE ||
      previousRouteWasOperatorIndex
    ) {
      return this.replaceWith(OPERATOR_EDIT_ROUTE, rulesetId, {
        queryParams,
      });
    }

    let findCustomObjectsPromise = async () => {
      return this.store.findAll('custom-objects/object-type');
    };

    return Promise.all([
      descriptors,
      findCustomObjectsPromise(),
      this.store.findAll('people/qualification-attribute'),
      this.store.findAll('workflow-connector/insertable-action'),
      this.store.findAll('messenger-app'),
    ]);
  }

  async model() {
    let [parentModel] = await Promise.all([
      super.model(...arguments),
      this.store.findRecord('messenger-settings/languages', this.appService.app.id),
    ]);
    return parentModel;
  }

  shouldTransitionToEdit(rulesetId) {
    let ruleset = this.store.peekRecord('matching-system/ruleset', rulesetId);
    return ruleset?.isNewRuleset;
  }

  afterModel(model) {
    this.outboundHomeService.setActiveList(objectTypes.customBot);
    model.rulesetLinks.firstObject.object.resetInvalidActions();
    super.afterModel(...arguments);
  }
}

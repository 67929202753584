/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Route from '@ember/routing/route';
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import type ArticleReviewsController from 'embercom/controllers/apps/app/knowledge-hub/article-reviews';

export default class ArticleReviews extends Route {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare router: Router;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;

  beforeModel() {
    if (!this.appService.app.canUseArticleReviews) {
      this.router.transitionTo('apps.app.knowledge-hub.all-content');
    }
  }

  async model() {
    return await this.store.query('articles/article-content-review-wrapper', {});
  }

  resetController(controller: ArticleReviewsController, isExiting: boolean) {
    if (isExiting) {
      this.knowledgeHubDrawerEditorService.removeEmberStickyQueryParams(controller);
    }
  }
}

/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import type Store from '@ember-data/store';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { tracked } from '@glimmer/tracking';
import { hash } from 'rsvp';
import { confirmSubscriptionChanges as confirmSubscriptionChangesFromInboxSeats } from 'embercom/lib/admins/inbox-seat-info';
export default class PermissionsRoute extends ManageTeammatesRoute {
  @service declare permissionsMutatorService: any;
  @service declare permissionsHierarchyService: any;
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare router: RouterService;
  @service declare notificationsService: any;

  @tracked seatTypes = [];
  hasSeatsToBeSaved = false;

  analytics = {
    section: 'settings',
    place: 'teammate-permissions',
  };

  closeWithoutSaving = false;

  async beforeModel() {
    await super.beforeModel(...arguments);
    this.closeWithoutSaving = false;
    this.hasSeatsToBeSaved = false;
    await this.permissionsHierarchyService.ensureDataIsLoaded.perform();

    let isPermissionsEmpty =
      (this.modelFor('apps.app.settings.workspace.teammates.teammate') as any).permissions
        .length === 0;

    if (isPermissionsEmpty) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.notifications.failed-loading-teammate'),
      );
    }

    if (
      this.appService.app.currentAdmin.id ===
        (this.modelFor('apps.app.settings.workspace.teammates.teammate') as any).id ||
      isPermissionsEmpty
    ) {
      await this.router
        .transitionTo('apps.app.settings.workspace.teammates', {
          queryParams: { forcePlatform: true },
        })
        .followRedirects();
    }
  }

  model() {
    let admin: any = this.modelFor('apps.app.settings.workspace.teammates.teammate');
    let index: any = this.modelFor('apps.app.settings.workspace.teammates.index');
    return hash({
      app: this.appService.app,
      admin,
      permissionsObject: admin.currentAppPermissions,
      reassignments: undefined,
      roles: this.store.findAll('role'),
      copilotData: index?.copilotData,
    });
  }

  get getCurrentModel(): any {
    return this.modelFor(this.routeName);
  }

  async saveChanges() {
    try {
      this.getCurrentModel.permissionsObject.reassignments = this.getCurrentModel.reassignments;
      if (this.appService.app.hasMultipleSeatTypes || this.appService.app.hasCopilotSeatType) {
        await this.permissionsMutatorService.savePermissionAndSeats(
          this.getCurrentModel.admin,
          this.getCurrentModel.permissionsObject,
          this.seatTypes,
        );
        this.hasSeatsToBeSaved = false;
        this.getCurrentModel.permissionsObject.rollbackAttributes();
        this.store.findRecord('admin', this.getCurrentModel.admin.id, {
          reload: true,
          backgroundReload: false,
        });
      } else {
        await this.permissionsMutatorService.savePermission(
          this.getCurrentModel.admin,
          this.getCurrentModel.permissionsObject,
          this.getCurrentModel.permissionsObject.has_inbox_access,
        );
        this.getCurrentModel.permissionsObject.rollbackAttributes();
        this.store.findRecord('admin', this.getCurrentModel.admin.id, {
          reload: true,
          backgroundReload: false,
        });
      }
      this.router.transitionTo('apps.app.settings.workspace.teammates.index');
    } catch (error) {
      console.error(error);
    }
  }

  get numberOfInboxSeatsRequired() {
    let model = this.getCurrentModel.permissionsObject;
    return model.hasDirtyAttributes &&
      model.changedAttributes().has_inbox_access &&
      model.has_inbox_access
      ? 1
      : 0;
  }

  @action
  // @ts-ignore
  async confirmAndSaveChanges(teammates: any, seatTypes: any, assigningCopilot = false) {
    this.seatTypes = seatTypes;
    if (
      await confirmSubscriptionChangesFromInboxSeats(
        this.numberOfInboxSeatsRequired,
        this.showConfirmModal,
        {
          confirmationButtonLabel: this.intl.t(
            'settings.teammates.permissions.save-changes-modal.save-and-fill-seat',
          ),
          editFlow: true,
        },
        true,
        assigningCopilot,
        this.getCurrentModel.copilotData,
      )
    ) {
      await this.saveChanges();
    }
  }

  @action
  async showConfirmModal(options: any) {
    return this.intercomConfirmService.confirm(options);
  }

  @action
  seatsUpdated(hasUpdatedSeats: boolean) {
    this.hasSeatsToBeSaved = hasUpdatedSeats;
  }

  @action
  async willTransition(transition: Transition) {
    if (this.closeWithoutSaving) {
      return;
    }

    let permissionsObject = this.getCurrentModel.permissionsObject;
    if (
      (permissionsObject && permissionsObject.hasDirtyAttributes) ||
      (this.hasSeatsToBeSaved && this.appService.app.hasMultipleSeatTypes)
    ) {
      transition.abort();

      let confirmOptions = {
        title: this.intl.t('settings.teammates.permissions.close-without-saving-modal.title'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'settings.teammates.permissions.close-without-saving-modal.close-without-saving',
        ),
        cancelButtonText: this.intl.t(
          'settings.teammates.permissions.close-without-saving-modal.keep-editing',
        ),
        body: this.intl.t('settings.teammates.permissions.close-without-saving-modal.message-body'),
      };

      if (await this.intercomConfirmService.confirm(confirmOptions)) {
        // I wish I didn't have to do this. Normally we would rollback then retry
        // which would run this action again and exit the route. However, doing that
        // in this route looks bad. The IcOnOffToggle components which are bound to
        // the model are animated with CSS transitions - so you'll see them animate
        // back to their original positions before we exit the route.
        this.closeWithoutSaving = true;
        await transition.retry();
        permissionsObject.rollbackAttributes();
      }
    }
  }
}

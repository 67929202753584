/* RESPONSIBLE TEAM: team-conversational-knowledge */

import { default as OriginalRoute } from 'embercom/routes/apps/app/automation/fin-ai-agent/content-suggestions';
import { inject as service } from '@ember/service';
import type ContentImportService from 'embercom/services/content-import-service';

export default class Route extends OriginalRoute {
  templateName = 'apps/app/automation/fin-ai-agent/content-suggestions';
  controllerName = 'apps/app/automation/fin-ai-agent/content-suggestions';
  @service declare contentImportService: ContentImportService;

  async model(params: { searchTerm: string }) {
    await this.contentImportService.fetchContentReviewSummary();
    return super.model(params);
  }
}

/* RESPONSIBLE TEAM: team-knowledge-foundations */

import EducateArticleListBase from 'embercom/routes/apps/app/articles/articles/list/educate-article-list-base';
import { inject as service } from '@ember/service';

export default class ViewsArticlesRoute extends EducateArticleListBase {
  @service intl;
  @service appService;
  @service helpCenterService;
  @service store;
  @service router;

  get selectedContentStatus() {
    return '';
  }

  get titleToken() {
    return this.intl.t('articles.shared.left-side-nav.views');
  }

  async beforeModel(transition) {
    let view = await this.store.peekRecord('content-service/saved-view', transition.to.params.id);
    if (!view) {
      this.router.transitionTo(`apps.app.articles.articles.list.all`);
    }
  }

  async model(_params) {
    let params = this.paramsFor('apps.app.articles');
    let view = await this.store.peekRecord('content-service/saved-view', _params.id);
    let savedViewData = view.savedViewData;
    let request = params.request ? JSON.parse(params.request) : savedViewData;
    // when clearing tags, we pass an empty string to the params. We need to handle that case here
    let tags = savedViewData.tags;
    if (params.selectedTagValues || params.selectedTagValues === '') {
      tags = params.selectedTagValues.length ? params.selectedTagValues.split(',') : [];
    }
    return {
      view,
      viewId: _params.id,
      selectedTagValues: tags,
      selectedContentStatus: '',
      locale: params.selectedLocaleId || null,
      title: view.name,
      articles: [],
      request: JSON.stringify({
        conditions: request.conditions,
        columns: request.columns,
        order: request.order ?? { key: 'updated_at', direction: 'desc' },
      }),
    };
  }
}

/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export default class ProcessesIndexRoute extends Route {
  @service declare router: RouterService;

  redirect(_model: any) {
    this.router.transitionTo('apps.app.standalone.tasks');
  }
}

/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default class FinStandaloneDeployRoute extends Route {
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('standalone.navbar.deploy');
  }
}

/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  store: service(),
  model() {
    let push = this.store.createRecord('mobile-push/push');
    push.localizedPushContents.pushObject(
      this.store.createRecord('mobile-push/localized-push-content', {
        push,
        uris: { android: '', ios: '' },
      }),
    );
    let ruleset = this.store.createRecord('matching-system/ruleset');
    ruleset.set('predicateGroup', this.store.createFragment('predicates/predicate-group'));
    ruleset.set('clientPredicateGroup', this.store.createFragment('predicates/predicate-group'));
    ruleset.set('rolePredicateGroup', this.store.createFragment('predicates/predicate-group'));
    ruleset.set('clientData', this.store.createRecord('matching-system/client-data'));
    let rulesetLink = this.store.createRecord('matching-system/ruleset-links/push', {
      type: 'matching-system/ruleset-links/push',
    });
    rulesetLink.set('object', push);
    ruleset.rulesetLinks.pushObject(rulesetLink);
    return ruleset;
  },
});

/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import { PAGE_SIZE } from 'embercom/models/data/outbound/constants';
import { getOwner } from '@ember/application';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import { REVIEWABLE_CONTENT_ENTITIES } from 'embercom/lib/ai-content-library/constants';
import { objectNamesToKeys } from 'embercom/models/data/matching-system/matching-constants';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import type Transition from '@ember/routing/transition';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import { EntityType } from 'embercom/models/data/entity-types';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import type FinContentReviewsController from 'embercom/controllers/apps/app/automation/fin-ai-agent/content-suggestions';
import type ContentSuggestionsService from 'embercom/services/content-suggestions-service';

type ModelParams = {
  searchTerm: string;
};

export default class AutomationFinAIAgentContentSuggestionsRoute extends Route {
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.content-suggestions',
  };

  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare contentSuggestionsService: ContentSuggestionsService;

  async model(params: ModelParams): Promise<AiContentLibraryApi> {
    let api = new AiContentLibraryApi(getOwner(this), {
      object_types: this.reviewableContentEntities,
      content_wrapper_types: [ContentWrapperType.AI_CONTENT],
      app_id: this.appService.app.id as string,
      per_page: PAGE_SIZE,
      content_review_statuses: [Status.Pending],
      title: params.searchTerm,
    });

    await Promise.all([api.loadPage()]);

    this.contentSuggestionsService.listApi = api;

    return api;
  }

  setupController(
    controller: $TSFixMe,
    model: ModelFromRoute<AutomationFinAIAgentContentSuggestionsRoute>,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    let queryParams = transition.to.queryParams;
    let contentType = queryParams.content;
    if (!contentType) {
      return;
    }
    if (!this.reviewableContentEntities.includes(Number(objectNamesToKeys[contentType]))) {
      controller.setProperties({
        selectedContentType: undefined,
        selectedContentId: undefined,
      });
      this.redirectToFinContent(transition);
    }
  }

  private redirectToFinContent(transition: Transition): void {
    this.router.transitionTo({
      queryParams: {
        ...transition.to.queryParams,
        selectedContentType: undefined,
        selectedContentId: undefined,
        content: undefined,
        id: undefined,
      },
    });
  }

  private get reviewableContentEntities(): EntityType[] {
    let reviewableContentEntities = [...REVIEWABLE_CONTENT_ENTITIES];
    if (this.appService.app.canUseAiInsightsSuggestionsUi) {
      reviewableContentEntities.push(EntityType.ArticleContent);
    }
    return reviewableContentEntities;
  }

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.content-suggestions.title');
  }

  resetController(controller: FinContentReviewsController, isExiting: boolean) {
    if (isExiting) {
      this.knowledgeHubDrawerEditorService.removeEmberStickyQueryParams(controller);
    }
  }
}

/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { IntegrationState } from 'embercom/objects/standalone/constants';

export default class FinStandaloneDeployZendeskTicketsRoute extends Route {
  @service declare router: RouterService;
  @service declare finStandaloneService: FinStandaloneService;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (
      this.finStandaloneService?.zendeskConfig?.ticketState === IntegrationState.Enabled ||
      this.finStandaloneService?.zendeskConfig?.ticketState === IntegrationState.Paused
    ) {
      this.router.transitionTo('apps.app.standalone.deploy.zendesk.tickets.go-live');
    } else {
      this.router.transitionTo('apps.app.standalone.deploy.zendesk.tickets.integrate');
    }
  }
}

/* RESPONSIBLE TEAM: team-self-serve */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';

export default class GettingStartedRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare greatGuidanceService: GreatGuidanceService;

  async model(_: $TSFixMe, transition: Transition) {
    let steps = await this.greatGuidanceService.fetchSteps(this.appService.app.id);
    if (transition.from?.name === 'apps_loading' && this.appService.app.isStandaloneApp) {
      let hasIncompleteSteps = steps.some((step) => !step.completed);

      if (!hasIncompleteSteps) {
        return this.transitionTo('apps.app.standalone.reports');
      }
    }
    return steps;
  }
}

/* RESPONSIBLE TEAM: team-purchase */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import { inject as service } from '@ember/service';
import AppstoreRoute from 'embercom/routes/base/appstore';

export default AppstoreRoute.extend({
  intercomEventService: service(),
  analytics: { place: 'public_app_store' },
  appstoreMetaTagsService: service(),
  gtmService: service(),

  queryParams: {
    category: {
      refreshModel: true,
    },
    search: {
      refreshModel: true,
    },
    capability: {
      refreshModel: true,
    },
    features: {
      refreshModel: false,
    },
  },

  headTags() {
    let category = this.controller?.get('selectedCategory');
    let capability = this.controller?.get('selectedCapability');
    return this.appstoreMetaTagsService.getMetaTags({ category, capability });
  },

  actions: {
    didTransition() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'visited',
        object: 'public_app_store',
      });

      if (this.appstoreService.shouldEnableGtmTracking()) {
        this.gtmService.loadThirdPartyScripts();
        this.gtmService.startTrackingPageViewsGlobally();
      }
    },
  },
});

/* RESPONSIBLE TEAM: team-phone */
import { inject as service } from '@ember/service';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import type RouterService from '@ember/routing/router-service';
import { get } from 'embercom/lib/ajax';
import { TwilioStringToPhoneNumberType } from 'embercom/models/settings/calling';

export default class PhoneRegulatoryBundlesNewRoute extends WorkspaceSettingsRoute {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare intl: any;

  beforeModel() {
    super.beforeModel(...arguments);
  }

  async model(params: any) {
    let bundle = await get('/ember/calling_settings/fetch_regulatory_bundle', {
      app_id: this.appService.app.id,
      id: params.id,
      for_sms: true,
    });

    return {
      regulatoryBundle: {
        sid: bundle.sid,
        countryCode: bundle.country_code,
        phoneNumberType: TwilioStringToPhoneNumberType.get(bundle.number_type),
        friendlyName: bundle.friendly_name,
        status: this.intl.t(`calling.settings.phone-regulatory-bundle.${bundle.status}`),
        failureReason: bundle.failure_reason,
      },
      endUser: bundle.end_user,
      supportingDocuments: bundle.supporting_documents,
    };
  }
}

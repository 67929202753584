/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import IntercomRoute from 'embercom/routes/base/intercom-route';

export default IntercomRoute.extend({
  analytics: {
    section: 'signup',
  },
  purchaseAnalyticsService: service(),
  boundUnloadEvent: null,
  appService: service(),
  experimentOptInService: service(),
  customerService: service(),
  gtmService: service(),
  router: service(),
  earlyStageService: service(),
  permissionsService: service(),
  cardlessTrialService: service(),
  store: service(),

  queryParams: {
    seats: {
      replace: true,
    },
    planId: {
      refreshModel: true,
    },
  },

  resetController(controller) {
    controller.set('extendedTrial', null);
    controller.set('seatNumber', null);
    controller.set('planId', null);
  },

  async beforeModel(transition) {
    await this.appService.app.reload();

    let goingToVerifyEmailRoute = transition?.to?.name === 'apps.app.teams-checkout.verify';

    if (!goingToVerifyEmailRoute && !this.currentAdminHasBillingPermissions()) {
      this.trackPermissionBlockEvent('can_access_billing_settings');
      this.permissionsService.cancelTransitionAndShowPermissionModal(
        'can_access_billing_settings',
        transition,
      );
      return;
    }

    if (!goingToVerifyEmailRoute && !this.currentAdminHasManageTeammatesPermissions()) {
      this.trackPermissionBlockEvent('can_manage_teammates');
      this.permissionsService.cancelTransitionAndShowPermissionModal(
        'can_manage_teammates',
        transition,
      );
      return;
    }

    if (this.appService.app.forceEmailVerification) {
      this.transitionTo('apps.app.teams-checkout.verify');
    }

    await this.customerService.refreshCustomer();
  },

  trackPermissionBlockEvent(permission) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'blocked',
      object: permission,
      context: 'permissions_denied',
      place: 'plan',
    });
  },

  currentAdminHasBillingPermissions() {
    return this.permissionsService.currentAdminCan('can_access_billing_settings');
  },

  currentAdminHasManageTeammatesPermissions() {
    return this.permissionsService.currentAdminCan('can_manage_teammates');
  },

  async setupController(controller, model, transition) {
    this._super(controller, model);
    this.purchaseAnalyticsService.setDefaults();

    await this.fetchCheckoutAndSetOnController.perform();
  },
  fetchCheckoutAndSetOnController: task(function* () {
    let checkout = yield this.store.findRecord('checkout', this.appService.app.id, {
      reload: true,
    });

    if (checkout.isNotReady) {
      // Timeout is managed server-side. It will fail open and return status = authorized if the app is more than 20 seconds old
      return later(this, () => this.fetchCheckoutAndSetOnController.perform(), ENV.APP._2000MS);
    } else {
      let controller = this.controllerFor('apps.app.teams-checkout');
      controller.set('checkout', checkout);
    }
  }),

  fireUnloadEvent() {
    let controller = this.controllerFor(this.router.currentRouteName);
    this.purchaseAnalyticsService.trackEvent({
      place: controller.place,
      context: controller.context,
      action: 'navigated_away',
      object: controller.place,
      mobile: controller.mobileDevice,
    });

    return 'fired navigated_away'; // need to return otherwise ajax doesn't fire.
  },

  deactivate() {
    this.showPrimaryNav();
    window.removeEventListener('beforeunload', this.boundUnloadEvent);
    this.set('boundUnloadEvent', null);
    document.body.classList.remove('signup__teams__body');
  },

  activate() {
    this.hidePrimaryNav();
    this.set('boundUnloadEvent', this.fireUnloadEvent.bind(this));
    window.addEventListener('beforeunload', this.boundUnloadEvent);
  },
});

/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
// Adding a model type as described here:
// https://docs.ember-cli-typescript.com/ember/routes
type Resolved<P> = P extends Promise<infer T> ? T : P;
export type FinAIAgentSetupRouteModel = Resolved<
  ReturnType<AutomationFinAiAgentSetupRoute['model']>
>;

export default class AutomationFinAiAgentSetupRoute extends Route {
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare router: RouterService;
  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.setup',
  };

  async beforeModel(transition: Transition) {
    let currentURLBeforeLoadingData = this.router.currentURL;
    await this.loadAiAgentSetupService();
    if (currentURLBeforeLoadingData !== this.router.currentURL) {
      return;
    }
    if (transition.to.queryParams?.activeTab) {
      if (transition.to.queryParams?.activeTab === 'personality') {
        this.router.replaceWith('apps.app.automation.fin-ai-agent.guidance');
        return;
      }
      if (transition.to.queryParams?.activeTab.startsWith('set-live-for-')) {
        let tab = transition.to.queryParams?.activeTab.split('set-live-for-')[1];
        this.router.replaceWith(`apps.app.automation.fin-ai-agent.deploy.${tab}`);
        return;
      }
    }
    let canSeeReports = this.permissionsService.currentAdminCan('can_access_reporting');
    if (this.aiAgentSetupService.isFinLive && canSeeReports) {
      this.router.replaceWith('apps.app.automation.fin-ai-agent.analyze.performance');
    } else {
      this.router.replaceWith('apps.app.automation.fin-ai-agent.content');
    }
    return;
  }

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.fin-overview.title');
  }

  async loadAiAgentSetupService() {
    await this.aiAgentSetupService.load();
  }

  @action
  didTransition() {
    this.intercomEventService.trackEvent('fin-automation-page-visited');
  }
}

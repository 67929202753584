/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { IntegrationState } from 'embercom/objects/standalone/constants';

export default class FinStandaloneDeployIntercomMessengerRoute extends Route {
  @service declare router: RouterService;
  @service declare finStandaloneService: FinStandaloneService;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    if (
      this.finStandaloneService.intercomMessengerConfig.integrationState ===
        IntegrationState.Enabled ||
      this.finStandaloneService.intercomMessengerConfig.integrationState === IntegrationState.Paused
    ) {
      this.router.transitionTo('apps.app.standalone.deploy.intercom-messenger.go-live');
    } else {
      this.router.transitionTo('apps.app.standalone.deploy.intercom-messenger.integrate');
    }
  }
}

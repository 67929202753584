/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import type Transition from '@ember/routing/transition';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';

export default class New extends Route {
  @service declare notificationsService: any;
  @service declare appService: any;
  @service declare router: Router;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare permissionsService: any;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;

  beforeModel(transition: Transition) {
    super.beforeModel(transition);
    // Displaying, editing and creating content is now handled by the in-context editor.
    // This route is remaining in place to redirect in case of user bookmarks etc
    let params = transition.to.params;
    if (params.content_type) {
      this.knowledgeHubDrawerEditorService.createNewContent({
        routeName: 'apps.app.knowledge-hub.all-content',
        activeContentType: params.content_type,
        folderId: params.folder_id,
        collectionId: params.collection_id,
        contentLocale: params.content_locale,
      });
    } else {
      this.router.transitionTo('apps.app.knowledge-hub.all-content');
    }
  }
}

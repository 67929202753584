/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import type IntercomMessengerTestController from 'embercom/controllers/apps/app/standalone/deploy/intercom-messenger/test';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export default class Test extends Route {
  @service declare finStandaloneService: FinStandaloneService;

  queryParams = {
    section: {
      refreshModel: false,
    },
  };

  setupController(
    controller: IntercomMessengerTestController,
    model: unknown,
    transition: Transition,
  ) {
    super.setupController(controller, model, transition);

    let params = this.paramsFor(this.routeName) as { section: string | null };
    controller.set('section', params.section ?? this.openSectionId);
  }

  resetController(
    controller: IntercomMessengerTestController,
    isExiting: boolean,
    transition: Transition,
  ) {
    super.resetController(controller, isExiting, transition);
    if (isExiting) {
      controller.set('section', null);
    }
  }

  get openSectionId() {
    if (!this.finStandaloneService.hasContentForFin) {
      return 'fin-ready';
    }

    if (this.finStandaloneService.intercomMessengerConfig.isMessengerInTestMode) {
      return 'test-mode';
    }

    return null;
  }
}

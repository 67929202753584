/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default class FinStandaloneChannelsRoute extends Route {
  @service declare appService: any;
  @service declare finStandaloneService: any;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('standalone.navbar.deploy');
  }

  async beforeModel(transition: Transition) {
    await this.finStandaloneService.initialize();

    if (transition.to.name === 'apps.app.standalone.channels.index') {
      await this.router.transitionTo('apps.app.standalone.deploy.overview');
    }
  }
}

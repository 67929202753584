/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router';
import type IntlService from 'embercom/services/intl';
export default class TestingStandaloneRoute extends Route {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  analytics = {
    section: 'standalone',
    place: 'standalone.testing',
  };

  get titleToken() {
    return this.intl.t('standalone.navbar.test');
  }

  beforeModel() {
    if (!this.appService.app.canUseFinPlayground) {
      this.router.transitionTo('apps.app.standalone.deploy.overview');
    }
  }
}

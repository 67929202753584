/* RESPONSIBLE TEAM: team-reporting */
import BaseProductIndexRoute from 'embercom/routes/base/product-index-route';
import storage from 'embercom/vendor/intercom/storage';
import { inject as service } from '@ember/service';
import { readOnly, and, not } from '@ember/object/computed';

export default BaseProductIndexRoute.extend({
  defaultRoutePath: 'apps.app.inbox',
  standaloneDefaultRoutePath: 'apps.app.standalone',
  appService: service(),
  customerService: service(),
  store: service(),
  app: readOnly('appService.app'),
  customer: readOnly('customerService.customer'),
  hasNoMessengerInstalled: not('app.hasAnyInstalledAtDate'),
  hasNotConfiguredEmailForwarding: not('app.has_setup_email_forwarding'),
  hasNotConfiguredMessengerOrEmailForwarding: and(
    'hasNoMessengerInstalled',
    'hasNotConfiguredEmailForwarding',
  ),
  showGuideLibrary: and('hasNotConfiguredMessengerOrEmailForwarding', 'customer.hasActiveTrials'),

  async afterModel(_model, transition) {
    await this.customerService.ensureDataIsLoaded.perform({ fetchPrices: false });

    let lastProductUsed = storage.get('lastProductUsed');

    if (transition.to.queryParams.forcePlatform) {
      this.replaceWith(this.defaultRoutePath);
    } else if (this.showGuideLibrary) {
      try {
        setTimeout(() => this.replaceWith('apps.app.getting-started'), 0);
      } catch (e) {
        console.error('Error while redirecting to guide librry:', 'Error:', e);
        this.replaceWith(this.defaultRoutePath);
      }
    } else if (lastProductUsed && !this.app.canUseStandalone) {
      setTimeout(() => {
        try {
          this.replaceWith(`apps.app.${lastProductUsed}`);
        } catch (e) {
          console.error(
            'Error during using lastProductUsed value to navigate. Key removed from local storage.',
            'lastProductUsed value:',
            lastProductUsed,
            'Error:',
            e,
          );
          storage.remove('lastProductUsed');
          this.replaceWith(this.defaultRoutePath);
        }
      }, 0);
    } else {
      this.replaceWith(
        this.app.canUseStandalone ? this.standaloneDefaultRoutePath : this.defaultRoutePath,
      );
    }
  },
});

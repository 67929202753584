/* RESPONSIBLE TEAM: team-knowledge-foundations */
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';

export default class AllHelpCentersRoute extends BaseSettingsRoute {
  @service declare intl: IntlService;
  @service declare store: Store;

  get titleToken() {
    return this.intl.t('new-settings.submenu.helpcenter.all-help-centers');
  }

  model() {
    let allHelpCenters = this.store.findAll('help-center-site');
    return {
      helpCenters: allHelpCenters,
    };
  }
}

/* RESPONSIBLE TEAM: team-ai-insights */
import InsightsV3Monitor from 'embercom/routes/apps/app/automation/insights-v3-monitor';
import type Transition from '@ember/routing/-private/transition';

export default class FinStandaloneInsightsV3Monitor extends InsightsV3Monitor {
  analytics = {
    section: 'standalone',
    place: 'insights-v3-monitor',
  };

  templateName = 'apps/app/automation/insights-v3-monitor';

  activate() {}

  async beforeModel(transition: Transition) {
    if (!this.appService.app.canSeeAiInsightsTopicsV1) {
      this.router.transitionTo('apps.app.standalone.setup');
      return;
    }

    this.permissionsService.ensurePermissionWhenTransitioning('can_access_reporting', transition);
  }
}

/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AppsAppContentEditRoute extends Route {
  @service store;
  @service intercomEventService;

  async model() {
    let { content_id } = this.paramsFor('apps.app.articles.articles.article-content');
    let store = this.store;

    let content = await store.findRecord('articles/article-content-for-editing', content_id, {
      reload: true,
    });
    let article = await store.findRecord('article-multilingual', content.articleId);
    content.article = article;

    this.intercomEventService.trackEvent('visited_article_composer', {
      action: 'visited',
      object: 'article',
      place: 'article_composer',
      owner: 'growth-sp',
    });

    return {
      content,
      article,
    };
  }
}

/* RESPONSIBLE TEAM: team-data-foundations */

import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import AccountRouteBase from '../../../account/base/account-route-base';

export default class TagsRoute extends AccountRouteBase {
  @service declare intl: IntlService;

  analytics = {
    section: 'settings',
    place: 'personal.visible-to-you.tags',
  };

  get titleToken() {
    return this.intl.t('new-settings.submenu.personal.visible-to-you');
  }
}

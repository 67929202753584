/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import ManageTeammatesRoute from 'embercom/routes/apps/app/settings/base/manage-teammates-route';
import { inject as service } from '@ember/service';
import { getWorkspaceSeatUsageAndPrices } from 'embercom/lib/admins/multiple-seats-info';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import { FIN_AI_COPILOT_BASE_ID } from 'embercom/lib/billing';
import { COPILOT_SEAT_TYPE } from 'embercom/lib/settings/seats/constants';
import type CopilotDataService from 'embercom/services/copilot-data-service';

export default class IndexRoute extends ManageTeammatesRoute {
  @service declare store: Store;
  @service appService: $TSFixMe;
  @service notificationsService: $TSFixMe;
  @service customerService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare copilotDataService: CopilotDataService;

  get titleToken() {
    return this.intl.t(`new-settings.submenu.workspace.teammates`);
  }

  async beforeModel() {
    await Promise.all([super.beforeModel(...arguments), this.customerService.refreshCustomer()]);
    // We wait for the app to refresh in the cache to prevent an edge case where booting directly
    // to the teammates page would cause the refesh to clobber the data loaded in the model hook here.
  }

  async model() {
    return {
      invitedAdmins: await this._getInvitedAdminsPromise(),
      pricePerInboxSeat: await this._getPricePerInboxSeatPromise(),
      roles: await this.store.findAll('role'),
      migrationState: null,
      copilotData: await this.copilotDataService.ensureSetup(),
      awayStatusReasons: this.store.findAll('away-status-reason'),
    };
  }

  afterModel(model: $TSFixMe) {
    this.customerService.set('pricePerInboxSeat', model.pricePerInboxSeat);
  }

  async _getInvitedAdminsPromise() {
    await this.appService.app.fetchAndUpdateAllAdminPermissions();
    return this.store.findAll('invited-admin').catch(() => {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.settings.teammates.index.invited-admins-unexpected-error'),
      );
    });
  }

  async _loadCopilotSeatUsageAndPrices() {
    let allSeatUsages = (await getWorkspaceSeatUsageAndPrices()) as Record<string, unknown>;
    await this.customerService.bulkLoadPrices([
      {
        planIds: [parseInt(FIN_AI_COPILOT_BASE_ID, 10)],
      },
    ]);
    let copilotPrice =
      this.customerService.prices.find((price: $TSFixMe) =>
        price.hasSamePlans([parseInt(FIN_AI_COPILOT_BASE_ID, 10)]),
      )?.perUnitPrice ?? 0;
    return {
      seatUsages: allSeatUsages[COPILOT_SEAT_TYPE],
      price: copilotPrice,
    };
  }

  async _getPricePerInboxSeatPromise() {
    let fallbackPrice = 0;
    let pricePerInboxSeat = this.customerService.customer?.pricePerSeat;
    if (pricePerInboxSeat) {
      return Promise.resolve(pricePerInboxSeat);
    }

    try {
      await this.customerService.loadData({ source: 'teammates-route' });
      let pricePlan = this._getFirstActivePricePlan();
      if (pricePlan === undefined) {
        pricePlan = this.customerService.migrationPrices.firstObject;
      }
      return pricePlan ? pricePlan.get('perProductData.price_per_agent') : fallbackPrice;
    } catch (e) {
      return fallbackPrice;
    }
  }

  _getFirstActivePricePlan() {
    let migrationPricePlans = this.customerService.migrationPrices;
    let activePlanIds = this.customerService.activePlanIds;
    return migrationPricePlans.find((price: $TSFixMe) => price.hasSamePlans(activePlanIds));
  }

  setupController(controller: $TSFixMe, model: $TSFixMe) {
    super.setupController(...arguments);
    controller.set('roles', model.roles);
    controller.set('invitedAdmins', model.invitedAdmins);
    controller.set('migrationState', model.migrationState);
    controller.set('copilotData', model.copilotData);
  }
}

/* RESPONSIBLE TEAM: team-ai-agent */
import type Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { hash } from 'rsvp';

export default class FinAIAgentTasksIndexRoute extends Route {
  @service declare intl: IntlService;
  @service appService: any;
  @service declare store: Store;

  get titleToken() {
    return this.intl.t('ai-agent.train.tasks');
  }

  async model() {
    let messengerSettingsLanguages = await this.store.findRecord(
      'messenger-settings/languages',
      this.appService.app.id,
    );

    return hash({
      languages: messengerSettingsLanguages,
    });
  }
}

/* RESPONSIBLE TEAM: team-ai-insights */
import FinInsights from 'embercom/routes/apps/app/automation/fin-insights';
import type Transition from '@ember/routing/-private/transition';

export default class FinStandaloneFinInsights extends FinInsights {
  analytics = {
    section: 'standalone',
    place: 'fin-insights',
  };

  templateName = 'apps/app/automation/fin-insights';

  activate() {}

  async beforeModel(transition: Transition) {
    if (!this.appService.app.canSeeAiInsightsTopicsV1) {
      this.router.transitionTo('apps.app.standalone.setup');
      return;
    }

    this.permissionsService.ensurePermissionWhenTransitioning('can_access_reporting', transition);
  }
}

/* RESPONSIBLE TEAM: team-standalone */
import { default as FinGuidanceRoute } from 'embercom/routes/apps/app/automation/fin-ai-agent/guidance';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router';
import type ContentImportService from 'embercom/services/content-import-service';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import { taskFor } from 'ember-concurrency-ts';
export default class GuidanceStandaloneRoute extends FinGuidanceRoute {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare contentImportService: ContentImportService;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;

  analytics = {
    section: 'standalone',
    place: 'standalone.guidance',
  };

  async beforeModel() {}

  async model() {
    let promises = [
      taskFor(this.reportingMetrics.setup).perform(),
      this.contentImportService.fetchContentIngestionState(),
      this.contentImportService.fetchAiContentLibrarySummary(),
      this.contentImportService.fetchContentReviewSummary(),
      this.loadDescriptors(),
      this.loadCustomObjects(),
    ];

    if (this.appService.app.canUseFinGuidanceGA) {
      promises.push(
        taskFor(this.reportingUnderlyingDataService.loadAttributeMappings).perform(
          'conversation.fin.guidelines_applied.guideline_ids',
          'conversation',
          true,
        ),
      );
    }
    await Promise.all(promises);
    return super.model();
  }

  async loadDescriptors() {
    try {
      await this.store.findAll('conversation-attributes/descriptor');
    } catch (e) {
      console.error(e);
    }
  }

  async loadCustomObjects() {
    try {
      await this.store.findAll('custom-objects/object-type');
    } catch (e) {
      console.error(e);
    }
  }
}

/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
export default class AppsAppArticlesSuggestions extends Route {
  @service declare appService: any;

  beforeModel() {
    if (!this.appService.app.canUseArticleSuggestions) {
      this.transitionTo('apps.app.articles.index');
    }
  }
}

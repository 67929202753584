/* RESPONSIBLE TEAM: team-purchase */
import type Store from '@ember-data/store';
import { type Router } from '@ember/routing';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Product from 'embercom/models/product';
import { hash } from 'rsvp';
import type Plan from 'embercom/models/plan';

export default class IntershopProductsProductPlansPlanRoute extends Route {
  @service declare notificationsService: any;
  @service declare router: Router;
  @service declare permissionsService: any;
  @service declare store: Store;
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare customerService: any;
  @service declare intershopService: any;

  beforeModel(): void {
    if (!this.permissionsService.currentAdminCan('can_access_billing_settings')) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'blocked',
        object: 'intershop_plans_pricing',
        place: 'billing_permissions',
      });
      this.router.transitionTo('apps.app.intershop.products.product', this.product.slug, {
        queryParams: {
          showPermissionsModal: true,
        },
      });
    }

    if (!this.intershopService.canAccessPricing) {
      this.router.transitionTo('apps.app.intershop.products.product', this.product.slug);
    }
  }

  model(params: any): any {
    return hash({
      product: this.product,
      plan: this.product.plans.findBy('slug', params.plan_slug),
      contract: this.contract,
    });
  }

  afterModel(model: any): void {
    if (model.contract.isSecondarySubscription) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'blocked',
        object: 'intershop_plans_pricing',
        place: 'secondary_workspace',
      });
      this.router.transitionTo('apps.app.intershop.products.product', model.product.slug, {
        queryParams: {
          showSecondaryWorkspaceModal: true,
        },
      });
      return;
    }

    if (model.plan === undefined) {
      this.notificationsService.notifyError(this.intl.t('intershop.error_message.plan_not_found'));
      this.router.transitionTo('apps.app.intershop');
      return;
    }

    // redirect to active plan if active plan and viewing plan tier lower active plan
    let billablePlanOnProduct: Plan | undefined = model.product.plans.find(
      (plan: Plan) => plan.billableCustomerPlan,
    );
    if (billablePlanOnProduct && model.plan.tierId < billablePlanOnProduct.tierId) {
      this.router.transitionTo(
        'apps.app.intershop.products.product.plans.plan',
        model.product.slug,
        billablePlanOnProduct.slug,
      );
    }
  }

  get contract(): any {
    return this.store.findRecord('billing/contract', this.appService.app.id, {
      reload: false,
      backgroundReload: false,
    });
  }

  get product(): Product {
    return this.modelFor('apps.app.intershop.products.product') as Product;
  }

  serialize(model: any) {
    return { plan_slug: model.slug };
  }
}

/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router';

const SALESFORCE_DEPLOY_PAGE_ROUTE_MAPPING = {
  'apps.app.standalone.channels.salesforce': 'apps.app.standalone.deploy.salesforce',
  'apps.app.standalone.channels.salesforce.cases': 'apps.app.standalone.deploy.salesforce.cases',
  'apps.app.standalone.channels.salesforce.cases.index':
    'apps.app.standalone.deploy.salesforce.cases.index',
  'apps.app.standalone.channels.salesforce.cases.workflow':
    'apps.app.standalone.deploy.salesforce.cases.workflow',
};

export default class FinStandaloneSalesforceChannelsRoute extends Route {
  @service declare router: Router;

  beforeModel(transition: Transition) {
    this.router.transitionTo(
      SALESFORCE_DEPLOY_PAGE_ROUTE_MAPPING[
        transition.to.name as keyof typeof SALESFORCE_DEPLOY_PAGE_ROUTE_MAPPING
      ] || 'apps.app.standalone.deploy.salesforce.cases',
    );
  }
}

/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default class FinAIAgentDeployEmailRoute extends Route {
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('ai-agent.deploy.email');
  }
}

/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type ZendeskTicketsIntegrateController from 'embercom/controllers/apps/app/standalone/deploy/zendesk/tickets/integrate';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
export default class Integrate extends Route {
  @service declare finStandaloneService: FinStandaloneService;

  queryParams = {
    section: {
      refreshModel: false,
    },
  };

  setupController(
    controller: ZendeskTicketsIntegrateController,
    model: unknown,
    transition: Transition,
  ) {
    super.setupController(controller, model, transition);

    let params = this.paramsFor(this.routeName) as { section: string };

    if (params.section) {
      controller.set('section', params.section);
    } else {
      controller.set('section', this.openSectionId);
    }
  }

  resetController(
    controller: ZendeskTicketsIntegrateController,
    isExiting: boolean,
    transition: Transition,
  ) {
    super.resetController(controller, isExiting, transition);
    if (isExiting) {
      controller.set('section', null);
    }
  }

  get openSectionId() {
    if (this.zendeskConfig.isPendingSetupForTickets) {
      return 'connect-api';
    }

    if (!this.hasFinIdentity) {
      return 'fin-identity';
    }

    return null;
  }

  get hasFinIdentity() {
    return Boolean(this.zendeskConfig.ticketsIntegration.finZendeskUserId);
  }

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }
}

/* RESPONSIBLE TEAM: team-self-serve */
import Route from '@ember/routing/route';

export default class SignupNew extends Route {
  beforeModel(transition) {
    this.replaceWith('signup.teams.index', {
      queryParams: transition.to.queryParams,
    });
  }
}

/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Route from '@ember/routing/route';
import { type FolderTreeItem } from 'embercom/components/knowledge-hub/folder-selector';

export const FOLDER_TREE_EXAMPLE: FolderTreeItem[] = [
  {
    id: 'folder_1',
    title: 'Folder 1',
    parent_id: null,
    children_ids: ['folder_1_1', 'folder_1_2'],
    level: 1,
  },
  {
    id: 'folder_1_1',
    title: 'Folder 1.1',
    parent_id: 'folder_1',
    children_ids: [],
    level: 2,
  },
  {
    id: 'folder_1_2',
    title: 'Folder 1.2',
    parent_id: 'folder_1',
    children_ids: [],
    level: 2,
  },
  {
    id: 'folder_2',
    title: 'Folder 2',
    parent_id: null,
    children_ids: ['folder_2_1', 'folder_2_2'],
    level: 1,
  },
  {
    id: 'folder_2_1',
    title: 'Folder 2.1',
    parent_id: 'folder_2',
    children_ids: ['folder_2_1_1'],
    level: 2,
  },
  {
    id: 'folder_2_1_1',
    title: 'Folder 2.1.1',
    parent_id: 'folder_2_1',
    children_ids: ['folder_2_1_1_1'],
    level: 3,
  },
  {
    id: 'folder_2_1_1_1',
    title: 'Folder 2.1.1.1',
    parent_id: 'folder_2_1_1',
    children_ids: [],
    level: 4,
  },
  {
    id: 'folder_2_2',
    title: 'Folder 2.2',
    parent_id: 'folder_2',
    children_ids: [],
    level: 2,
  },
  {
    id: 'folder_3',
    title: 'Folder 3',
    parent_id: null,
    children_ids: [],
    level: 1,
  },
];

export default class FolderSelectorRoute extends Route {
  model(): FolderTreeItem[] {
    return FOLDER_TREE_EXAMPLE;
  }
}

/* RESPONSIBLE TEAM: team-reporting */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

export default class DataExportRoute extends Route {
  @service declare permissionsService: $TSFixMe;
  @service intl!: IntlService;
  @service declare appService: $TSFixMe;

  titleToken = this.intl.t('reporting.data-export.header');

  beforeModel(transition: any) {
    if (this.appService.app.canUseNewDataExport) {
      return this.permissionsService.ensurePermissionWhenTransitioning(
        'can_export_csv',
        transition,
      );
    } else {
      return this.transitionTo('apps.app.reports.overview');
    }
  }
}

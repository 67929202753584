/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  store: service(),
  notificationsService: service(),
  titleToken: alias('model.titleForDisplay'),

  async model(params) {
    // Prevent Ember data from its default behavior when a cached model exists
    // (which is to resolve this promise immediately with the cached model and
    // to asynchronously update).
    // We can't do that because of how the composer is bound.
    try {
      return await this.store.findRecord('article-multilingual', params.id, {
        reload: true,
      });
    } catch (e) {
      this.notificationsService.notifyError('Sorry that article cannot be found');
      this.replaceWith('apps.app.articles.articles.list.all');
    }
  },

  afterModel(model) {
    model.loadStats.perform();
  },

  actions: {
    returnToShowPage() {
      this.replaceWith('apps.app.articles.articles.article.show');
    },
  },
});

/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import { inject as service } from '@ember/service';
import setupLineChartSignal from 'embercom/lib/inbox/reporting/setup-line-chart-signal';
import percent from 'embercom/lib/percentage-formatter';
import {
  SLA_CONVERSATION_WAIT_TIME,
  SLA_STATUS_BY_CONVERSATION,
  SLA_STATUS_BY_TRIGGERING_COMMENT,
  SLA_STATUS_BY_TRIGGERING_COMMENT_AT_BREACH,
} from 'embercom/lib/reporting/queries';
import Signal from 'embercom/models/reporting/signal';
import ReportBase from 'embercom/routes/apps/app/reports/base/report';
import { hash } from 'rsvp';
import {
  parsedESFiltersForSignal,
  setControllerFilterProperties,
  filterQueryParams,
  setRouteFilterProperties,
  getFlexibleFiltersFromRoute,
} from 'embercom/lib/reporting/flexible/filter-helpers';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { buildNestedFieldFilters } from 'embercom/lib/reporting/nested-field-filters';

const SECONDS_IN_MINUTE = 60;

const TARGET_TYPE_KEYS = {
  firstReplyTime: 'first_reply_time',
  nextReplyTime: 'next_reply_time',
  timeToResolve: 'time_to_resolve',
  timeToClose: 'time_to_close',
};

const TARGET_TYPE_KEYS_TO_NAME = {
  first_reply_time: 'firstReplyTime',
  next_reply_time: 'nextReplyTime',
  time_to_resolve: 'timeToResolve',
  time_to_close: 'timeToClose',
};

const BREACHED_SLA_STATUSES = ['fixed', 'missed'];

const GROUP_BY_STATUS = {
  grouping: 'sla_status',
  interval: 1,
};

const GROUP_BY_TARGET_TYPE = {
  grouping: 'sla_target_type',
  interval: 1,
};

const QUERY_PARAMS_ES_MAPPINGS = {
  teammateId: 'attributed_to_admin_id',
  teamsParticipated: 'admin_participant_ids', //a list of teammate ids is passed to ES
  scopingTagIds: 'conversation_tag_ids',
  teammatesAssigned: 'admin_assignee_id',
  channels: 'channel_type',
  continents: 'user_location.continent_code',
  countries: 'user_location.country_name',
  startedByUser: 'conversation_started_by_user',
  teamsAssigned: 'team_assignee_id',
  customAttributes: 'custom_attributes',
  slas: 'conversation_sla_ids',
  topics: 'topic',
};
const FILTER_TYPES = Object.keys(QUERY_PARAMS_ES_MAPPINGS);

let filterByTargetType = (target) => ({
  sla_target_types: [TARGET_TYPE_KEYS[target]],
});

let filterBySLAStatus = (statuses = []) => ({
  sla_statuses: statuses,
});

let reduceSignalForComparisonContexts = (signal, mapContextToKeys) => (reduce) =>
  ['originalContext', 'previousContext'].reduce(
    (statusByContext, context) => ({
      ...statusByContext,
      [mapContextToKeys ? mapContextToKeys[context] : context]: reduce(signal[context]),
    }),
    {},
  );

let createKeyedValuesForContexts = (signal) =>
  reduceSignalForComparisonContexts(signal, {
    originalContext: 'value',
    previousContext: 'previousValue',
  })((context) =>
    context.reduce(
      (statusByKey, { key, value = 0 } = {}) => ({
        ...statusByKey,
        [TARGET_TYPE_KEYS_TO_NAME[key]]: value / SECONDS_IN_MINUTE,
      }),
      { firstReplyTime: 0, nextReplyTime: 0, timeToResolve: 0, timeToClose: 0 },
    ),
  );

let combineHourValuesForSLA = ({ hours: [first, second], sla }) => ({
  key: first.key,
  count: first.count + second.count,
  value: first.value + second.value,
  metadata: {
    firstReplyTime: sla.firstReplyTimeInSeconds && {
      count: first.count,
      value: first.value,
    },
    nextReplyTime: sla.nextReplyTimeInSeconds && {
      count: second.count,
      value: second.value,
    },
  },
});

let combineDayValuesForSLA = ({ days: [first, second], sla }) => ({
  key: first.key,
  count: first.count + second.count,
  value: first.value.map((hour, i) =>
    combineHourValuesForSLA({ hours: [hour, second.value[i]], sla }),
  ),
});

let defaultHeatmap = { originalContext: [], originalValue: 0 };

let combineHeatmapsForSLA = ({
  heatmaps: [firstReplyHeatmap = defaultHeatmap, nextReplyHeatmap = defaultHeatmap],
  sla,
}) =>
  Signal.create({
    valueUnit: firstReplyHeatmap.valueUnit,
    aggregations: firstReplyHeatmap.aggregations,
    timezone: firstReplyHeatmap.timezone,
    name: firstReplyHeatmap.name,
    originalValue: firstReplyHeatmap.originalValue + nextReplyHeatmap.originalValue,
    originalContext: firstReplyHeatmap.originalContext.map((day, i) =>
      combineDayValuesForSLA({ days: [day, nextReplyHeatmap.originalContext[i]], sla }),
    ),
    datePickerRangeStart: firstReplyHeatmap.rangeStart,
    datePickerRangeEnd: firstReplyHeatmap.rangeEnd,
  });

let transformToPercentHit = (signal, key) => ({
  ...signal,
  name: TARGET_TYPE_KEYS_TO_NAME[key],
  originalContext: (signal.originalContext || []).map(({ key, count, value }) => ({
    key,
    count,
    value: percent(count, (value.find((item) => item.key === 'hit') || { count: 0 }).count) || 0,
  })),
});

let fetchSignals = ({ reportingService, range, conversationSLA, filters }) => {
  let signalPromises = {
    slaConversations: reportingService.fetchComparisonSignal(
      SLA_STATUS_BY_CONVERSATION,
      'conversations',
      range,
      filters,
      [GROUP_BY_STATUS],
    ),
    slaMissedConversations: reportingService.fetchComparisonSignal(
      SLA_STATUS_BY_CONVERSATION,
      'conversations',
      range,
      {
        ...filterBySLAStatus(BREACHED_SLA_STATUSES),
        ...filters,
      },
    ),
  };

  if (conversationSLA) {
    // These signals are used in charts that require a conversationSLA
    let slaSpecificSignals = {
      firstReplyTargets: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'conversations',
        range,
        {
          ...filterByTargetType('firstReplyTime'),
          ...filters,
        },
        [GROUP_BY_STATUS],
      ),
      nextReplyTargets: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'conversations',
        range,
        {
          ...filterByTargetType('nextReplyTime'),
          ...filters,
        },
        [GROUP_BY_STATUS],
      ),
      resolutionTargets: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'conversations',
        range,
        {
          ...filterByTargetType('timeToResolve'),
          ...filters,
        },
        [GROUP_BY_STATUS],
      ),
      timeToCloseTargets: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'conversations',
        range,
        {
          ...filterByTargetType('timeToClose'),
          ...filters,
        },
        [GROUP_BY_STATUS],
      ),
      firstReplyMisses: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'conversations',
        range,
        {
          ...filterBySLAStatus(BREACHED_SLA_STATUSES),
          ...filterByTargetType('firstReplyTime'),
          ...filters,
        },
        [GROUP_BY_STATUS],
      ),
      nextReplyMisses: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'conversations',
        range,
        {
          ...filterBySLAStatus(BREACHED_SLA_STATUSES),
          ...filterByTargetType('nextReplyTime'),
          ...filters,
        },
        [GROUP_BY_STATUS],
      ),
      resolutionMisses: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'conversations',
        range,
        {
          ...filterBySLAStatus(BREACHED_SLA_STATUSES),
          ...filterByTargetType('timeToResolve'),
          ...filters,
        },
        [GROUP_BY_STATUS],
      ),
      timeToCloseMisses: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'conversations',
        range,
        {
          ...filterBySLAStatus(BREACHED_SLA_STATUSES),
          ...filterByTargetType('timeToClose'),
          ...filters,
        },
        [GROUP_BY_STATUS],
      ),
      slaWaitTime: reportingService.fetchComparisonSignal(
        SLA_CONVERSATION_WAIT_TIME,
        'conversations',
        range,
        {
          ...SLA_CONVERSATION_WAIT_TIME.filters,
          ...filters,
        },
        [GROUP_BY_TARGET_TYPE],
      ),
      firstReplyHitsOverTime: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'hits',
        range,
        {
          ...filterByTargetType('firstReplyTime'),
          ...filters,
        },
        [...range.baseAggregations, GROUP_BY_STATUS],
      ),
      nextReplyHitsOverTime: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'hits',
        range,
        {
          ...filterByTargetType('nextReplyTime'),
          ...filters,
        },
        [...range.baseAggregations, GROUP_BY_STATUS],
      ),
      resolutionHitsOverTime: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'hits',
        range,
        {
          ...filterByTargetType('timeToResolve'),
          ...filters,
        },
        [...range.baseAggregations, GROUP_BY_STATUS],
      ),
      timeToCloseHitsOverTime: reportingService.fetchComparisonSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT,
        'hits',
        range,
        {
          ...filterByTargetType('timeToClose'),
          ...filters,
        },
        [...range.baseAggregations, GROUP_BY_STATUS],
      ),
      firstReplyBreachesHeatmap: reportingService.fetchHeatmapSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT_AT_BREACH,
        'misses',
        range,
        {
          ...filterBySLAStatus(BREACHED_SLA_STATUSES),
          ...filterByTargetType('firstReplyTime'),
          ...filters,
        },
      ),
      nextReplyBreachesHeatmap: reportingService.fetchHeatmapSignal(
        SLA_STATUS_BY_TRIGGERING_COMMENT_AT_BREACH,
        'misses',
        range,
        {
          ...filterBySLAStatus(BREACHED_SLA_STATUSES),
          ...filterByTargetType('nextReplyTime'),
          ...filters,
        },
      ),
    };

    signalPromises = { ...signalPromises, ...slaSpecificSignals };
  }

  return hash(signalPromises).then((signals) => {
    let [breachCount, previousBreachCount] = [
      signals.slaMissedConversations.originalValue,
      signals.slaMissedConversations.previousValue,
    ];

    let extraSlaSpecificSignals = {};
    if (conversationSLA) {
      let {
        firstReplyTimeInSeconds,
        nextReplyTimeInSeconds,
        resolutionTimeInSeconds,
        timeToCloseInSeconds,
      } = conversationSLA;

      let hitsOverTimeList = [
        firstReplyTimeInSeconds &&
          transformToPercentHit(signals.firstReplyHitsOverTime, 'first_reply_time'),
        nextReplyTimeInSeconds &&
          transformToPercentHit(signals.nextReplyHitsOverTime, 'next_reply_time'),
        resolutionTimeInSeconds &&
          transformToPercentHit(signals.resolutionHitsOverTime, 'time_to_resolve'),
        timeToCloseInSeconds &&
          transformToPercentHit(signals.timeToCloseHitsOverTime, 'time_to_close'),
      ];

      extraSlaSpecificSignals = {
        combinedBreachesHeatmap: combineHeatmapsForSLA({
          heatmaps: [signals.firstReplyBreachesHeatmap, signals.nextReplyBreachesHeatmap],
          sla: conversationSLA,
        }),
        hitsOverTime: setupLineChartSignal(hitsOverTimeList.filter((signal) => signal)),
        waitTimeByTarget: createKeyedValuesForContexts(signals.slaWaitTime),
      };
    }

    return {
      ...signals,
      conversationMissRate: {
        value: percent(signals.slaConversations.value, breachCount) || 0,
        previousValue: percent(signals.slaConversations.previousValue, previousBreachCount) || 0,
      },
      conversationSuccesRate: {
        value:
          percent(signals.slaConversations.value, signals.slaConversations.value - breachCount) ||
          0,
        previousValue:
          percent(
            signals.slaConversations.previousValue,
            signals.slaConversations.previousValue - previousBreachCount,
          ) || 0,
      },
      conversationBreaches: {
        value: breachCount,
        previousValue: previousBreachCount,
      },
      ...extraSlaSpecificSignals,
    };
  });
};

let selectSLA = (slas, slaId, defaultSla) => {
  if (slaId || slas.length > 0) {
    return slaId ? slas.find(({ id }) => id === slaId) : defaultSla;
  }
};

export default ReportBase.extend({
  store: service(),
  reportingMetrics: service(),
  customReportsService: service(),
  reportName: 'slas_report',
  selectedConversationSLA: null,
  titleToken: 'SLAs',
  analytics: {
    place: 'slas',
  },
  queryParams: filterQueryParams(FILTER_TYPES),
  shouldRedirectFromParent: true,
  mergedProperties: ['queryParams'],

  filters(selectedConversationSLA) {
    let queryParamMappings = Object.assign({}, QUERY_PARAMS_ES_MAPPINGS);
    if (!this.appService.app.canUseTopicFilter) {
      delete queryParamMappings.topics;
    }

    let rawFilters = parsedESFiltersForSignal(this, queryParamMappings);
    let { custom_attributes, ...filters } = rawFilters || {};
    let nestedFilters = buildNestedFieldFilters(custom_attributes);

    if (nestedFilters) {
      filters.nested_filters = nestedFilters;
    }
    return filters;
  },

  get filtersForSignalFlexible() {
    let queryParamMappings = Object.assign({}, QUERY_PARAMS_ES_MAPPINGS);
    queryParamMappings['slas'] = 'conversation_sla_id'; // the Elasticsearch field is singular
    return getFlexibleFiltersFromRoute(this, queryParamMappings);
  },

  async beforeModel(transition) {
    this._super(...arguments);

    if (this.appService.app.cannotSeeDeprecatedReports) {
      this.customReportsService.routeToOverviewPage();
    }

    this.reportingMetrics.loadMetricsAndProperties();

    //setup filters
    let filters = FILTER_TYPES.filter((filter) => {
      if (filter === 'topics') {
        return this.appService.app.canUseTopicFilter;
      }

      return true;
    });
    setRouteFilterProperties(this, transition.to.queryParams, filters);

    let app = this.modelFor('apps.app');
    if (!app.get('canUseInboxSlas')) {
      this.transitionTo('apps.app.reports.overview');
    }
  },

  async model(params) {
    let { rules, slas } = await hash({
      rules: this.store.findAll('inbox/conversation-rules/sla'),
      slas: this.store.findAll('inbox/conversation-sla'),
    });

    let conversationAttributeDescriptors = [];

    if (this.app.canUseFeature('conversation_attributes')) {
      conversationAttributeDescriptors =
        await ConversationAttributeDescriptor.peekAllAndMaybeLoad();
    }

    let selectedConversationSLA;
    // Use the `slas` queryParam for the slaId when using the filter bar
    let slaId = this.slas[0];
    selectedConversationSLA = selectSLA(slas, slaId, null);

    let settings = {
      canUseTopicFilter: this.app.canUseTopicFilter,
    };

    return hash({
      conversationSLAs: slas,
      conversationSLARulesCount: rules.length,
      selectedConversationSLA,
      signals: fetchSignals({
        reportingService: this.reportingService,
        range: this.range,
        conversationSLA: selectedConversationSLA,
        filters: this.filters(selectedConversationSLA),
      }),
      conversationAttributeDescriptors,
      settings,
      filtersForSignalFlexible: this.filtersForSignalFlexible,
    });
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.setProperties({
      slaId: model.selectedConversationSLA && model.selectedConversationSLA.id,
      selectedConversationSLA: model.selectedConversationSLA,
      conversationSLAs: model.conversationSLAs,
      conversationSLARulesCount: model.conversationSLARulesCount,
      signals: model.signals,
    });
    setControllerFilterProperties(this, controller, FILTER_TYPES);
  },
});

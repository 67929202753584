/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import type ZendeskMessagingIntegrateController from 'embercom/controllers/apps/app/standalone/deploy/zendesk/messaging/integrate';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { inject as service } from '@ember/service';
import type Controller from '@ember/controller';
import { isEmpty } from 'underscore';

interface IntegrateQueryParams {
  section?: string;
}

export default class Integrate extends Route {
  @service declare finStandaloneService: FinStandaloneService;

  queryParams = {
    section: {
      refreshModel: false,
    },
  };

  setupController(controller: Controller, model: unknown, transition: Transition) {
    super.setupController(controller, model, transition);

    let params = this.paramsFor(this.routeName) as IntegrateQueryParams;
    let section = params.section;

    if (section) {
      (controller as ZendeskMessagingIntegrateController).set('section', section);
    } else {
      (controller as ZendeskMessagingIntegrateController).set('section', this.openSectionId);
    }
  }

  resetController(
    controller: ZendeskMessagingIntegrateController,
    isExiting: boolean,
    _transition: Transition,
  ) {
    if (isExiting) {
      controller.set('section', null);
    }
  }

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig!;
  }

  get openSectionId(): string | null {
    if (!this.isSunshineConnected) {
      return 'connect-zendesk-apis';
    }

    if (this.finIdentityIncomplete) {
      return 'zendesk-setup-fin-messenger_identity';
    }

    if (!this.hasSunshineChannelsSetup) {
      return 'sunshine-channels';
    }

    return null;
  }

  get isSunshineConnected() {
    return this.zendeskConfig.sunshineIntegration.isSunshineConnected;
  }

  get finIdentityIncomplete() {
    return (
      this.zendeskConfig.isPendingSetupForTickets ||
      !this.isSunshineConnected ||
      !this.finStandaloneService.operatorIdentity
    );
  }

  get hasSunshineChannelsSetup() {
    return !isEmpty(this.zendeskConfig.sunshineIntegration.supportedChannelIntegrationIds);
  }
}

/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SignupTeamsIndex extends Route {
  @service purchaseAnalyticsService;
  @service intl;
  locale;

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    this.purchaseAnalyticsService.setDefaults();
    controller.hideWidgetIfAppropriate();

    if (controller.firstName && controller.lastName) {
      controller.set(
        'name',
        `${capitalize(controller.firstName)} ${capitalize(controller.lastName)}`,
      );
    }

    if (transition.to.queryParams.qa) {
      controller.model.setProperties({
        email: `bob+qa-${new Date().valueOf()}viaurl@intercom.io`,
        appName: 'IntercomQATest',
        name: 'Bob QATest', // eslint-disable-line @intercom/intercom/no-bare-strings
      });
    }
    if (this.intl.supportedLocales.includes(transition.to.queryParams.locale)) {
      this.locale = transition.to.queryParams.locale;
    } else {
      this.locale = 'en-US';
      this.controllerFor('signup/teams').changeLocale('en-US');
      this.transitionTo({ queryParams: { locale: 'en-US' } });
    }
  }

  @action didTransition() {
    let controller = this.controllerFor('signup.teams.index');
    let event = {
      action: 'viewed',
      context: 'usecase_signup_flow',
      place: 'create_account',
      object: 'create_account',
      section: 'create_account',
      gclid: controller.gclid,
      emailSubmissionId: controller.emailSubmissionId,
      via_single_sign_on: controller.get('appInstall.viaSingleSignOn'),
      mobile: this.get('controller.mobileDevice'),
      developer: controller.developer,
      locale: this.locale,
    };

    this.purchaseAnalyticsService.trackPageView(event);
  }
}

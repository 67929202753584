/* RESPONSIBLE TEAM: team-app-security */

import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
// @ts-ignore
import { copy } from 'ember-copy';
import type IntlService from 'ember-intl/services/intl';
import ENV from 'embercom/config/environment';
import IntercomRoute from 'embercom/routes/base/intercom-route';

export default class PreferencesRoute extends IntercomRoute {
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;

  analytics = {
    section: 'settings',
    place: 'personal.preferences',
  };

  beforeModel(transition: Transition) {
    let app = this.modelFor('apps.app');
    this._notifyConfirmationForQueryParams(transition.to.queryParams, app);
  }

  async _notifyConfirmationForQueryParams(
    queryParams: { [key: string]: string | undefined },
    app: $TSFixMe,
  ) {
    let originalQueryParams = copy(queryParams);
    if (isPresent(queryParams) && !isEmpty(queryParams)) {
      if (queryParams['verifiedAdminEmail'] === 'true') {
        await app.currentAdmin.reload();
        this.notificationsService.notifyConfirmation(
          this.intl.t('account.general.email-verified', { email: app.get('currentAdmin.email') }),
          ENV.APP._15000MS,
        );
        delete queryParams['verifiedAdminEmail'];
      } else if (queryParams['verifiedAdminEmail'] === 'false') {
        this.notificationsService.notifyError(
          this.intl.t('account.general.could-not-verified', {
            email: app.get('currentAdmin.email'),
          }),
          ENV.APP._15000MS,
        );
        delete queryParams['verifiedAdminEmail'];
      }
      if (originalQueryParams !== queryParams) {
        this.transitionTo('apps.app.settings.personal.preferences', app, { queryParams });
      }
    }
  }
}

/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import {
  parsedESFilters,
  setControllerFilterProperties,
  filterQueryParams,
  setRouteFilterProperties,
} from 'embercom/lib/reporting/flexible/filter-helpers';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';

const QUERY_PARAMS_ES_MAPPINGS = {
  teammateId: 'admin_participant_ids',
  teamsParticipated: 'admin_participant_ids', //a list of teammate ids is passed to ES
  scopingTagIds: 'conversation_tag_ids',
  channels: 'channel_type',
  continents: 'user_location.continent_code',
  countries: 'user_location.country_name',
  startedByUser: 'conversation_started_by_user',
  teammatesAssigned: 'admin_assignee_id',
  teamsAssigned: 'team_assignee_id',
  customAttributes: 'custom_attributes',
};
const FILTER_TYPES = Object.keys(QUERY_PARAMS_ES_MAPPINGS);

export default class EffectivenessRoute extends IntercomRoute {
  @service appService;
  @service store;
  @service reportingService;
  @service customReportsService;
  analytics = { place: 'effectiveness' };
  mergedProperties = ['queryParams'];
  queryParams = filterQueryParams(FILTER_TYPES);
  reportName = 'effectiveness_dashboard';
  reportContext = 'effectiveness';
  titleToken = 'Effectiveness';

  dashboardIdentifier = 'effectiveness';
  chartIdentifiers = [
    'number-of-teammate-replies-to-close-counter',
    'number-of-teammate-replies-to-close-columnchart',
    'first-assignment-time-counter',
    'first-assignment-time-columnchart',
    'first-assignment-to-last-close-time-counter',
    'first-assignment-to-last-close-time-columnchart',
  ];

  shouldRedirectFromParent = true;

  get app() {
    return this.appService.app;
  }

  get range() {
    return this.reportingService.range;
  }

  filters() {
    let queryParamMappings = Object.assign({}, QUERY_PARAMS_ES_MAPPINGS);
    if (!this.app.canUseFeature('conversation_attributes')) {
      delete queryParamMappings.customAttributes;
    }
    return parsedESFilters(this, queryParamMappings);
  }

  beforeModel(transition) {
    super.beforeModel(...arguments);
    if (this.app.cannotSeeDeprecatedReports) {
      this.customReportsService.routeToOverviewPage();
    }

    //setup filters
    setRouteFilterProperties(this, transition.to.queryParams, FILTER_TYPES);
  }

  findDataConfig(dataConfigs, chartId) {
    let result = dataConfigs.findBy('chart_identifier', chartId);

    if (!result) {
      result = this.store.createRecord('reporting/chart', {
        dashboard_identifier: this.dashboardIdentifier,
        chart_identifier: chartId,
        app_id: this.app.id,
      });
    }

    return result;
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    let { range, reportContext, reportName, tag } = this;
    let controllerProperties = {
      rangeStart: range.start,
      rangeEnd: range.end,
      range,
      rangeParams: {
        interval: this.range.interval,
        comparisonStartMoment: this.range.comparisonStartMoment,
        comparisonEndMoment: this.range.comparisonEndMoment,
        startMoment: this.range.startMoment,
        endMoment: this.range.endMoment,
        inDays: this.range.inDays,
        timezone: this.range.timezone,
      },
      reportContext,
      reportName,
      tag,
      ...model,
    };
    controller.setProperties(controllerProperties);
    setControllerFilterProperties(this, controller, FILTER_TYPES);
  }

  async model() {
    let conversationAttributeDescriptors = [];
    let reportingAllSettings = await this.store.queryRecord('reporting/settings', {});
    let fetchedDataConfigs = await this.store.query('reporting/chart', {
      dashboard_identifier: this.dashboardIdentifier,
    });

    let dataConfigs = this.chartIdentifiers.reduce((result, id) => {
      result[id] = this.findDataConfig(fetchedDataConfigs, id);
      return result;
    }, {});

    let settings = {
      reportingExcludeOooHours: reportingAllSettings.get('excludeOooHours'),
    };

    if (this.app.canUseFeature('conversation_attributes')) {
      conversationAttributeDescriptors =
        await ConversationAttributeDescriptor.peekAllAndMaybeLoad();
    }

    return hash({
      settings,
      dataConfigs,
      dashboardIdentifier: this.dashboardIdentifier,
      conversationAttributeDescriptors,
    });
  }
}

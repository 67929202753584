/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default class AutomationFinAIAgentContentRoute extends Route {
  @service declare intl: IntlService;

  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.content',
  };

  get titleToken() {
    return this.intl.t('ai-agent.train.content');
  }
}

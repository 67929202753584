/* RESPONSIBLE TEAM: team-reporting */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { inject as service } from '@ember/service';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type Transition from '@ember/routing/transition';

export default class InsightsV3MonitorRoute extends IntercomRoute {
  @service intl!: IntlService;
  @service appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @service declare intercomEventService: any;
  @service declare store: any;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare permissionsService: $TSFixMe;
  analytics = { place: 'automation-insights-monitor' };
  reportName = 'automation-insights-monitor';
  reportContext = 'overview';
  // TODO translate all strings once they've been finalized
  /* eslint-disable @intercom/intercom/no-bare-strings */
  titleToken = 'Insights';

  activate() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: this.reportName,
      context: this.reportContext,
    });
  }

  async beforeModel(transition: Transition) {
    if (!this.appService.app.canSeeAiInsightsTopicsV1) {
      this.router.transitionTo('apps.app.automation.fin-ai-agent.setup');
      return;
    }
    this.permissionsService.ensurePermissionWhenTransitioning('can_access_reporting', transition);
  }

  async model() {
    let promises = [
      taskFor(this.reportingUnderlyingDataService.loadAttributeMappings).perform(
        'conversation.workflow_conversation_rating.workflow_id',
        'conversation',
        true,
      ),
      this.store.findAll('conversational-insights/conversation-topic'),
      taskFor(this.reportingMetrics.setup).perform(),
    ];

    if (this.appService.app.canUseFinGuidanceGA) {
      promises.push(
        taskFor(this.reportingUnderlyingDataService.loadAttributeMappings).perform(
          'conversation.fin.guidelines_applied.guideline_ids',
          'conversation',
          true,
        ),
      );
    }
    await Promise.all(promises);

    return {
      conversationAttributeDescriptors: ConversationAttributeDescriptor.peekAllAndMaybeLoad(),
    };
  }
}

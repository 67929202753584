/* RESPONSIBLE TEAM: team-channels */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Transition from '@ember/routing/-private/transition';
import type RouterService from '@ember/routing/router-service';

export default class AllChannelsRoute extends SettingsRoute {
  @service declare permissionsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  get titleToken() {
    return this.intl.t('new-settings.channels.all.title');
  }

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_product_settings',
      transition,
    );
  }
}
